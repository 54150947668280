import { inject } from '@angular/core';

import { WINDOW } from './window-token';

/** Gets subdomain from current window location. */
export function getWindowUrlSubdomain(): string {
	const window = inject(WINDOW);

	const { hostname } = window.location;
	const [subdomain] = hostname.split('.');

	return subdomain;
}

/** Gets hostname from current window location. */
export function getWindowUrlHostname(): string {
	const window = inject(WINDOW);

	const { hostname } = window.location;
	const hostnameParts = hostname.split('.');
	hostnameParts.shift();

	return hostname.includes('localhost') ? 'ygm.saritasa.rocks' : hostnameParts.join('.');
}
