import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/** Icon declaration. */
export interface IconDeclaration {

	/** Icon title. The icon will be accessible for mat-icon by this name. */
	readonly title: string;

	/** URL to the icon. */
	readonly url: string;
}

/** Icons service. */
@Injectable({
	providedIn: 'root',
})
export class IconsService {

	public constructor(
		private readonly iconRegistry: MatIconRegistry,
		private readonly sanitizer: DomSanitizer,
	) { }

	/**
	 * Adds an svg icon.
	 * @param icon Icon declaration.
	 * @param baseUrl Base URL of the icon.
	 */
	public addIcon(icon: IconDeclaration, baseUrl = ''): void {
		const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl + icon.url);
		this.iconRegistry.addSvgIcon(icon.title, safeUrl);
	}
}
