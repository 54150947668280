import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { ForgotPasswordData, Login } from '../../models/login';
import { ForgotPasswordDto, LoginDto } from '../dto/login.dto';
import { ValidationErrorDto } from '../dto/validation-error.dto';

import { extractErrorMessageByField } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Login data mapper. */
@Injectable({
	providedIn: 'root',
})
export class LoginDataMapper
implements
    IMapperToDto<LoginDto, Login>,
    IValidationErrorMapper<LoginDto, Login> {
	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<LoginDto>,
	): EntityValidationErrors<Login> {
		return {
			email: extractErrorMessageByField('email', errorDto),
			password: extractErrorMessageByField('password', errorDto),
		};
	}

	/** @inheritdoc */
	public toDto(data: Login): LoginDto {
		return {
			email: data.email,
			password: data.password,
			chamber_id: data.chamberId,
		};
	}

	/**
	 * Maps forgot password data to dto.
	 * @param data Forgot password data.
	 */
	public forgotPasswordToDto(data: ForgotPasswordData): ForgotPasswordDto {
		return {
			email: data.email,
			chamber_id: data.chamberId,
		};
	}
}
