import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { trackByIndex } from '@ygm/common/core/utils/trackby';

export const ERROR_NOTIFICATION_DURATION_MS = 5000;

interface ErrorData {

	/** Error message. */
	readonly error: string | string[];
}

/** Error notifier component. */
@Component({
	selector: 'ygmc-error-notifier',
	templateUrl: './error-notifier.component.html',
	styleUrls: ['./error-notifier.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule],
})
export class ErrorNotifierComponent {
	private readonly data = inject<ErrorData>(MAT_SNACK_BAR_DATA);

	/** Errors list. */
	protected readonly errors: string[] = [];

	/** Track by function for errors. */
	protected readonly trackErrors = trackByIndex;

	public constructor() {
		this.errors = this.errors.concat(this.data.error);
	}

	/** Whether the app has errors or not. */
	public get hasErrors(): boolean {
		return this.errors.length > 0;
	}
}
