import { enumToArray } from '@ygm/common/core/utils/enum-to-array';

/** Contract type. */
export enum ContractType {
	Cash = 'cash',
	Trade = 'trade',
	Other = 'other',
}

/** Contract type utility functions. */
export namespace ContractType {
	const TO_READABLE: Record<ContractType, string> = {
		[ContractType.Cash]: 'Cash',
		[ContractType.Trade]: 'Trade',
		[ContractType.Other]: 'Other',
	};

	/** Represent ContractType enum as an array. */
	export function toArray(): ContractType[] {
		return enumToArray(ContractType);
	}

	/**
	 * Provide human-readable value for contract type.
	 * @param type Contract type.
	 */
	export function toReadable(type: ContractType): string {
		return TO_READABLE[type];
	}
}
