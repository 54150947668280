import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CampaignStatus } from '@ygm/common/core/models/campaign';
import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';

/** Guard prevents user from accessing if the current campaign status is created or live. */
export function activeCampaignGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);

	return chamberValidationService.isSubdomainValid$.pipe(
		map(isSubdomainValid =>
			isSubdomainValid && chamberValidationService.chamberInformation.campaign?.status === CampaignStatus.Renewal ?
				true :
				router.parseUrl(routePaths.dashboard.path)),
		first(),
	);
}
