<div
	class="error-notification"
	*ngIf="hasErrors"
>
	<h1 class="error-notification__title typography-headline">Error!</h1>
	<hr class="error-notification__divider" />
	<p
		*ngIf="errors.length === 1; else errorsList"
		class="error-notification__message"
	>
		{{ errors[0] }}
	</p>
</div>
<ng-template #errorsList>
	<ul>
		<li
			class="error-notification__message list-item"
			*ngFor="let errorMessage of errors; trackBy: trackErrors"
		>
			{{ errorMessage }}
		</li>
	</ul>
</ng-template>
