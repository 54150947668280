import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Login } from '@ygm/common/core/models/login';
import { UserSecret } from '@ygm/common/core/models/user-secret';
import { AppErrorMapper } from '@ygm/common/core/services/mappers/app-error.mapper';
import { UserSecretDto } from '@ygm/common/core/services/dto/user-secret.dto';
import { LoginDataMapper } from '@ygm/common/core/services/mappers/login-data.mapper';
import { UserSecretDataMapper } from '@ygm/common/core/services/mappers/user-secret-data.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VolunteerRegistrationData, VolunteerRegistrationInfo, VolunteerRegistrationParams } from '../models/volunteer-registration-data';

import { PublicAppUrlsConfig } from './public-app-urls.config';
import { VolunteerRegistrationInfoDto } from './dto/volunteer-registration.dto';
import { RegistrationDataMapper } from './mappers/volunteer-registration.mapper';

/**
 * Performs CRUD operations for auth-related information.
 */
@Injectable({ providedIn: 'root' })
export class AuthApiService {

	private readonly httpClient = inject(HttpClient);

	private readonly apiUrlsConfig = inject(PublicAppUrlsConfig);

	private readonly loginDataMapper = inject(LoginDataMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly userSecretMapper = inject(UserSecretDataMapper);

	private readonly registrationDataMapper = inject(RegistrationDataMapper);

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<UserSecret> {
		return this.httpClient.post<UserSecretDto>(
			this.apiUrlsConfig.auth.login,
			this.loginDataMapper.toDto(loginData),
		)
			.pipe(
				map(dto => this.userSecretMapper.fromDto(dto)),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.loginDataMapper,
				),
			);
	}

	/**
	 * Create account for volunteer.
	 * @param registrationData Registration data.
	 */
	public register(registrationData: VolunteerRegistrationData): Observable<UserSecret> {
		return this.httpClient.post<UserSecretDto>(
			this.apiUrlsConfig.auth.register,
			this.registrationDataMapper.toDto(registrationData),
		)
			.pipe(
				map(dto => this.userSecretMapper.fromDto(dto)),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.registrationDataMapper,
				),
			);
	}

	/** Logs out. */
	public logout(): Observable<void> {
		return this.httpClient.post<void>(this.apiUrlsConfig.auth.logout, {});
	}

	/**
	 * Get registration info.
	 * @param registrationInfoParams Registration info params.
	 */
	public getRegistrationInfo(registrationInfoParams: VolunteerRegistrationParams): Observable<VolunteerRegistrationInfo> {
		const params = this.registrationDataMapper.registrationInfoParamsToDto(registrationInfoParams);
		return this.httpClient.get<VolunteerRegistrationInfoDto>(
			this.apiUrlsConfig.auth.registerInfo, { params },
		).pipe(
			map(dto => this.registrationDataMapper.fromRegistrationInfo(dto)),
		);
	}
}
