import { Injectable, inject } from '@angular/core';
import {
	IMapperFromDetailsDto,
	IMapperFromDto,
	IMapperToCreationDto,
	IMapperToEditDto,
	IValidationErrorMapper,
} from '@ygm/common/core/services/mappers/mappers';
import { CampaignMapper } from '@ygm/common/core/services/mappers/campaign.mapper';
import { ChamberMapper } from '@ygm/common/core/services/mappers/chamber.mapper';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { ContractMemberDto } from '@ygm/common/core/services/dto/contract-member.dto';
import { ContractMember } from '@ygm/common/core/models/contract-member';
import { formatDate } from '@angular/common';
import { CampaignUserMapper } from '@ygm/common/core/services/mappers/campaign-user.mapper';
import { MemberMapper } from '@ygm/common/core/services/mappers/member.mapper';
import { LevelMapper as CommonLevelInstance } from '@ygm/common/core/services/mappers/level.mapper';
import { ContractStatusMapper } from '@ygm/common/core/services/mappers/contract-status.mapper';
import { ContractTypeMapper } from '@ygm/common/core/services/mappers/contract-type.mapper';

import {
	Contract,
	ContractCreationData,
	ContractDetails,
	ContractEditData,
} from '../../../models/contract/contract';
import {
	ContractCreationDto,
	ContractDetailsDto,
	ContractDto,
	ContractEditDto,
} from '../../dto/contract/contract.dto';

/** Contract mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractMapper implements
		IMapperFromDto<ContractDto, Contract>,
		IMapperFromDetailsDto<ContractDetailsDto, ContractDetails>,
		IMapperToCreationDto<ContractCreationDto, ContractCreationData>,
		IMapperToEditDto<ContractEditDto, ContractEditData>,
		IValidationErrorMapper<ContractCreationDto, ContractCreationData> {

	private readonly campaignMapper = inject(CampaignMapper);

	private readonly chamberMapper = inject(ChamberMapper);

	private readonly commonLevelInstance = inject(CommonLevelInstance);

	private readonly campaignUserMapper = inject(CampaignUserMapper);

	private readonly memberMapper = inject(MemberMapper);

	private readonly contractStatusMapper = inject(ContractStatusMapper);

	private readonly contractTypeMapper = inject(ContractTypeMapper);

	/** @inheritdoc */
	public fromDto(dto: ContractDto): Contract {
		return {
			id: dto.id,
			createdByUserId: dto.created_by,
			name: dto.name,
			status: this.contractStatusMapper.fromDto(dto.status),
			isApproved: dto.is_approved,
			isRenewed: dto.is_renewed,
			note: dto.note || null,
			type: this.contractTypeMapper.fromDto(dto.type),
			levels: dto.levels.map(levelDto => this.commonLevelInstance.fromInstanceDto(levelDto)),
			levelsCount: dto.levels.length,
			totalCost: dto.levels.reduce((prevCost, currentLevel) => prevCost + currentLevel.cost, 0),
			member: this.fromMemberDto(dto.member),
			shareCreditsWith: dto.shared_credits_with.map(this.campaignUserMapper.fromDto),
			campaign: this.campaignMapper.fromDto(dto.campaign),
			hasMergeableContracts: dto.hasMergeableContracts,
		};
	}

	/** @inheritdoc */
	public fromDetailsDto(dto: ContractDetailsDto): ContractDetails {
		return {
			id: dto.id,
			name: dto.name,
			status: this.contractStatusMapper.fromDto(dto.status),
			isApproved: dto.is_approved,
			type: this.contractTypeMapper.fromDto(dto.type),
			levels: dto.levels.map(levelInstanceDto => this.commonLevelInstance.fromInstanceDto(levelInstanceDto)),
			levelsCount: dto.levels_count,
			totalCost: dto.total_cost,
			member: this.fromMemberDto(dto.member),
			chamber: this.chamberMapper.fromCreationDto(dto.chamber),
			campaign: this.campaignMapper.fromDto(dto.campaign),
			privateNote: dto.private_note,
			signedAt: dto.signed_at !== null ? new Date(dto.signed_at) : null,
			token: dto.token,
			hasMergeableContracts: dto.hasMergeableContracts,
			renewalNote: dto.renewal_note,
			contractNote: dto.contract_note,
		};
	}

	/** @inheritdoc */
	public toCreationDto(data: ContractCreationData): ContractCreationDto {
		return {
			name: data.name,
			levels: data.levels.map(level => this.commonLevelInstance.toInstanceCreationDto(level)),
			member: this.toMemberDto({
				...data.member,
				storedMemberId: data.member.storedMember?.id ?? null,
				storedMember: undefined,
			}),
			note: data.note ?? '',
			shared_credits_with: data.shareCreditWith.map(user => user.id),
			type: this.contractTypeMapper.toDto(data.type),
			status: this.contractStatusMapper.toDto(data.status),
			created_date: formatDate(data.createdDate, 'MM/dd/YYYY', 'en-US'),
		};
	}

	/** @inheritdoc */
	public toEditDto(data: ContractEditData): ContractEditDto {
		return this.toCreationDto(data);
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<ContractCreationDto>): EntityValidationErrors<ContractCreationData> {
		return {
			name: extractErrorMessageByField('name', errorDto),
			levels: extractErrorMessageByField('levels', errorDto),
			member: {
				name: extractErrorMessageByField('member.name', errorDto),
				firstName: extractErrorMessageByField('member.first_name', errorDto),
				lastName: extractErrorMessageByField('member.last_name', errorDto),
				email: extractErrorMessageByField('member.email', errorDto),
				address: extractErrorMessageByField('member.address', errorDto),
				city: extractErrorMessageByField('member.city', errorDto),
				state: extractErrorMessageByField('member.state', errorDto),
				zipcode: extractErrorMessageByField('member.zipcode', errorDto),
				mobilePhone: extractErrorMessageByField('member.mobile_phone', errorDto),
				workPhone: extractErrorMessageByField('member.work_phone', errorDto),
			},
			note: extractErrorMessageByField('note', errorDto),
			shareCreditWith: extractErrorMessageByField('shared_credits_with', errorDto),
			type: extractErrorMessageByField('type', errorDto),
		};
	}

	private fromMemberDto(dto: ContractMemberDto): ContractMember {
		return {
			name: dto.name,
			firstName: dto.first_name,
			lastName: dto.last_name,
			address: dto.address,
			city: dto.city,
			email: dto.email,
			mobilePhone: dto.mobile_phone,
			state: dto.state,
			workPhone: dto.work_phone,
			zipcode: dto.zipcode,
			storedMemberId: dto.stored_member_id,
			storedMember: dto.stored_member ? this.memberMapper.fromDto(dto.stored_member) : undefined,
		};
	}

	private toMemberDto(dto: ContractMember): ContractMemberDto {
		return {
			name: dto.name,
			first_name: dto.firstName,
			last_name: dto.lastName,
			address: dto.address,
			city: dto.city,
			email: dto.email,
			mobile_phone: dto.mobilePhone,
			state: dto.state,
			work_phone: dto.workPhone,
			zipcode: dto.zipcode,
			stored_member_id: dto.storedMemberId,
		};
	}
}
