import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CampaignStatus } from '@ygm/common/core/models/campaign';
import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';
import { Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { UserService } from '../services/user.service';

/** Guard prevents user from accessing if the current user is not cloaked in closed campaign. */
export function notCloakedUserCampaignGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);

	const userService = inject(UserService);

	return combineLatest([userService.currentUser$, chamberValidationService.chamberInformation$]).pipe(
		map(([user, chamberInfo]) =>
			chamberInfo.campaign?.status === CampaignStatus.Closed && !user?.isCloaked ? router.parseUrl(routePaths.landing.path) : true)
		,
		first(),
	);
}
