import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type NotificationAction = () => void;

interface NotificationData {

	/** Notification message. */
	readonly message: string;

	/** Dialog action. */
	readonly action?: NotificationAction;
}

/** Service for global notifications. */
@Injectable({
	providedIn: 'root',
})
export class NotificationService {

	/** Error message. */
	public readonly errorMessage$ = new BehaviorSubject<string | string[] | null>(null);

	/** Notification message. */
	public readonly notificationMessage$ = new BehaviorSubject<NotificationData | null>(null);

	/**
	 * Sets error message.
	 * @param errorMessage Error message to set.
	 */
	public setError(errorMessage: string | string[]): void {
		this.errorMessage$.next(errorMessage);
	}

	/**
	 * Set notification message.
	 * @param message Message.
	 * @param action Action after the user closed the notification.
	 */
	public setMessage(message: string, action?: NotificationAction): void {
		this.notificationMessage$.next({
			message,
			action,
		});
	}
}
