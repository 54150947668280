import { UserRole } from '@ygm/common/core/models/user-role';

import { User } from '../../../core/models/user/user';

/**
 * Check whether the user has all required information.
 * @param user User to check.
 */
export function hasRequiredInfo(user: User): boolean {
	return (
		!!(
			user.companyName &&
			user.title &&
			user.companyAddress &&
			user.companyCity &&
			user.companyState &&
			user.companyZipCode &&
			user.preferredContactMethods.length > 0
		) || user.role === UserRole.SuperAdmin || user.role === UserRole.ChamberAdmin
	);
}
