import { Injectable, inject } from '@angular/core';
import { ApiPathResource } from '@ygm/common/core/models/api-path-resource';
import { AppConfig } from '@ygm/common/core/services/app.config';
import { Level } from '@ygm/common/core/models/inventory/level';

import { Contract } from '../models/contract/contract';
import { ProductCategory } from '../models/product-category';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class PublicAppUrlsConfig {

	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.buildPath('auth/login/'),
		logout: this.toApi('auth/logout/'),
		register: this.buildPath('auth/register/'),
		registerInfo: this.buildPath('auth/register-info/'),
		refreshSecret: this.buildPath('auth/password-reset/'),
		resetPassword: this.buildPath('auth/token/refresh/'),
		confirmPasswordReset: this.buildPath('auth/password-reset-confirm/'),
	} as const;

	/** Routes for checking chamber information. */
	public readonly chamber = {
		subdomainCheck: this.buildPath('chamber/subdomain-exist'),
	};

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: () => this.buildPath('me/'),
		changePassword: this.buildPath('users/change_password/'),
	} as const;

	/** Routes for dashboard data. */
	public readonly dashboard = {
		statistics: this.buildPath('dashboard/stats/'),
		recentlySold: this.buildPath('dashboard/recently-sold/'),
	} as const;

	/** Routes for contracts. */
	public readonly contract = {
		list: this.buildPath('contracts/'),
		entity: (id: Contract['id']) => this.buildPath(`contracts/${id}/`),
		internalView: (id: Contract['id']) => this.buildPath(`contracts/${id}/internal-view/`),
		publicView: (token: string) => this.buildPath(`contracts/${token}/`, ApiPathResource.Public),
		attachLevel: (id: Contract['id']) => this.buildPath(`contracts/${id}/attach-level/`),
		sign: (token: string) => this.buildPath(`contracts/${token}/sign/`, ApiPathResource.Public),
	} as const;

	/** Routes for product. */
	public readonly product = {
		list: this.buildPath('products/', ApiPathResource.Public),
		categoriesList: this.buildPath('product-categories/', ApiPathResource.Public),
		categoryEntity: (id: ProductCategory['id']) => this.buildPath(`product-categories/${id}/`, ApiPathResource.Public),
		levelsList: this.buildPath('levels/', ApiPathResource.Public),
		levelEntity: (id: Level['id']) => this.buildPath(`levels/${id}/`, ApiPathResource.Public),
		purchasingLevelsList: this.buildPath('purchased-levels/', ApiPathResource.Public),
	};

	/** Routes for member. */
	public readonly member = {
		list: this.buildPath('stored-members/'),
	};

	/** Routes for purchasing member. */
	public readonly purchasingMember = {
		list: this.buildPath('purchasing-members/', ApiPathResource.Public),
	};

	/** Routes for campaign user. */
	public readonly campaignUser = {
		list: this.buildPath('users/'),
	};

	/** Routes for remaining sponsorships. */
	public readonly remainingSponsorships = {
		list: this.buildPath('remaining-sponsorship/'),
	};

	/** Routes for campaign. */
	public readonly campaign = {
		statistics: this.buildPath('campaigns/landing-page/', ApiPathResource.Public),
	};

	/** Routes for resources. */
	public readonly resource = {
		list: this.buildPath('resources/'),
	};

	/** Routes for campaign user standings. */
	public readonly standings = {
		teamStandings: this.buildPath('team-standings/'),
		volunteerStandings: this.buildPath('volunteer-standings/'),
		leadershipStandings: this.buildPath('leadership-standings/'),
	};

	/** Routes for campaign user standings. */
	public readonly invoices = {
		publicView: (token: string) => this.buildPath(`invoices/${token}/`, ApiPathResource.Public),
	};

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private buildPath(path: string, pathResource: ApiPathResource = ApiPathResource.Volunteer): string {
		return this.toApi(pathResource.concat(path));
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
