/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';

/** Contract action validation mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractActionValidationMapper {

	/**
	 * Maps validation from dto.
	 * @param errorDto Error dto.
	 */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<any>,
	): EntityValidationErrors<any> {
		return {
			levels: extractErrorMessageByField('levels', errorDto),
		};
	}
}
