import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CampaignStatus } from '@ygm/common/core/models/campaign';
import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';

/** Guard prevents user from accessing if the current campaign is done. */
export function doneCampaignGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();

	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);

	return chamberValidationService.chamberInformation$.pipe(
		map(chamberInfo =>
			chamberInfo.campaign?.status === CampaignStatus.Done ? router.parseUrl(routePaths.landing.path) : true),
		first(),
	);
}
