import { Injectable, inject } from '@angular/core';
import { IMapperFromDto } from '@ygm/common/core/services/mappers/mappers';

import { ChamberProfileDetails } from '../../models/chamber/chamber-profile-details';
import { ChamberProfileDetailsDto } from '../dto/chamber-profile-details.dto';

import { CampaignMapper } from './campaign.mapper';
import { ChamberBrandingMapper } from './chamber-branding.mapper';

/** Chamber profile details mapper. */
@Injectable({
	providedIn: 'root',
})
export class ChamberProfileDetailsMapper implements IMapperFromDto<ChamberProfileDetailsDto, ChamberProfileDetails> {

	private readonly chamberBrandingMapper = inject(ChamberBrandingMapper);

	private readonly campaignMapper = inject(CampaignMapper);

	/** @inheritdoc */
	public fromDto(dto: ChamberProfileDetailsDto): ChamberProfileDetails {
		return {
			id: dto.id,
			name: dto.name,
			subdomain: dto.subdomain,
			ceoEmail: dto.ceo_email,
			trcCoordEmail: dto.trc_coord_email,
			mailingAddress: dto.mail_address,
			phoneNumber: dto.phone,
			physicalAddress: dto.address,
			physicalAddressCity: dto.city,
			physicalAddressCountry: dto.country,
			physicalAddressState: dto.state,
			physicalAddressZip: dto.zipcode,
			facebookUrl: dto.facebook_url,
			instagramUrl: dto.instagram_url,
			linkedinUrl: dto.linkedin_url,
			twitterUrl: dto.twitter_url,
			youtubeUrl: dto.youtube_url,
			campaign: dto.campaign ? this.campaignMapper.fromDto(dto.campaign) : null,
			branding: this.chamberBrandingMapper.fromDto(dto.branding),
		};
	}
}
