import { Injectable } from '@angular/core';
import { IMapperFromDto } from '@ygm/common/core/services/mappers/mappers';
import { CampaignUser } from '@ygm/common/core/models/campaign/campaign-user';
import { CAMPAIGN_USER_ROLE_FROM_DTO_MAP } from '@ygm/common/core/services/mappers/campaign-user-role.mapper';

import { CampaignUserDto } from '../dto/campaign-user.dto';

/** CampaignUser mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignUserMapper implements IMapperFromDto<CampaignUserDto, CampaignUser> {

	/** @inheritdoc */
	public fromDto(dto: CampaignUserDto): CampaignUser {
		return {
			id: dto.id,
			companyName: dto.company_name,
			email: dto.email,
			firstName: dto.first_name,
			lastName: dto.last_name,
			role: CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role],
			avatar: dto.avatar,
			teamId: dto.team,
			memberId: dto.member,
			memberName: dto.member_name,
			contractsAmount: Number(dto.contract_count),
			deactivatedAt: dto.deactivated_at,
			lastLogin: dto.last_login,
			isCloaked: dto.is_cloaked,
		};
	}
}
