import { Injectable } from '@angular/core';

import { PasswordConfirmationDto } from '../dto/password-confirmation.dto';
import { PasswordConfirmation } from '../../models/password-confirmation';

import { EntityValidationErrors } from '../../models/app-error';
import { ValidationErrorDto } from '../dto/validation-error.dto';

import { IMapperToDto, IValidationErrorMapper } from './mappers';
import { extractErrorMessageByField } from './extract-error-message';

/** Password confirmation mapper. */
@Injectable({
	providedIn: 'root',
})
export class PasswordConfirmationMapper implements
		IMapperToDto<PasswordConfirmationDto, PasswordConfirmation>,
		IValidationErrorMapper<PasswordConfirmationDto, PasswordConfirmation> {
	/** @inheritdoc */
	public toDto(data: PasswordConfirmation): PasswordConfirmationDto {
		return {
			uid: data.uid,
			password: data.password,
			password_confirm: data.passwordConfirm,
			token: data.token,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<PasswordConfirmationDto>,
	): EntityValidationErrors<PasswordConfirmation> {

		// Show a user friendly message if the `uid` or `token` is invalid.
		const tokenError = extractErrorMessageByField('uid', errorDto) || extractErrorMessageByField('token', errorDto) ?
			'Your password reset token is invalid' :
			undefined;

		return {
			password: extractErrorMessageByField('password', errorDto),
			passwordConfirm: extractErrorMessageByField('password_confirm', errorDto) ?? tokenError,
		};
	}
}
