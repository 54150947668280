import { inject, Injectable } from '@angular/core';
import { Login } from '@ygm/common/core/models/login';
import { UserSecretStorageService } from '@ygm/common/core/services/user-secret-storage.service';
import { BehaviorSubject, combineLatest, first, ignoreElements, map, merge, Observable, of, OperatorFunction, pipe, shareReplay, switchMap, tap } from 'rxjs';
import { UserSecret } from '@ygm/common/core/models/user-secret';

import { UserRole } from '@ygm/common/core/models/user-role';

import { User } from '../models/user/user';
import { VolunteerRegistrationData, VolunteerRegistrationInfo, VolunteerRegistrationParams } from '../models/volunteer-registration-data';

import { AuthApiService } from './auth-api.service';
import { UserApiService } from './user-api.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
	providedIn: 'root',
})
export class UserService {

	/** Current user. `null` when a user is not logged in. */
	public readonly currentUser$: Observable<User | null>;

	/** Whether the current user is authorized. */
	public readonly isAuthorized$: Observable<boolean>;

	private readonly authService = inject(AuthApiService);

	private readonly userSecretStorage = inject(UserSecretStorageService);

	private readonly userApiService = inject(UserApiService);

	private _currentUser: User | null = null;

	private readonly _refreshUser$ = new BehaviorSubject<void>(undefined);

	public constructor() {
		this.currentUser$ = this.initCurrentUserStream();
		this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
	}

	/** Get current user synchronously. */
	public get currentUserSync(): User | null {
		return this._currentUser;
	}

	/**
	 * Get registration info.
	 * @param registrationInfoParams Registration info params.
	 */
	public getRegistrationInfo(registrationInfoParams: VolunteerRegistrationParams): Observable<VolunteerRegistrationInfo> {
		return this.authService.getRegistrationInfo(registrationInfoParams);
	}

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<void> {
		return this.authService.login(loginData).pipe(
			this.saveSecretAndWaitForAuthorized(),
		);
	}

	/**
	 * Register a new volunteer and save secret token.
	 * @param registrationData Registration data.
	 */
	public register(registrationData: VolunteerRegistrationData): Observable<void> {
		return this.authService.register(registrationData).pipe(
			this.saveSecretAndWaitForAuthorized(),
			map(() => undefined),
		);
	}

	private saveSecretAndWaitForAuthorized(): OperatorFunction<UserSecret, void> {
		return pipe(
			switchMap(secret => {
				const saveUserSecretSideEffect$ = this.userSecretStorage.saveSecret(secret).pipe(ignoreElements());

				return merge(
					this.isAuthorized$,
					saveUserSecretSideEffect$,
				);
			}),
			first(isAuthorized => isAuthorized),
			map(() => undefined),
		);
	}

	/**
	 * Logout current user.
	 */
	public logout(): Observable<void> {
		return this.authService.logout().pipe(
			switchMap(() => this.userSecretStorage.removeSecret()),
		);
	}

	private initCurrentUserStream(): Observable<User | null> {
		return combineLatest([
			this.userSecretStorage.currentSecret$,
			this._refreshUser$,
		]).pipe(
			switchMap(([secret]) => (secret ? this.userApiService.getCurrentUser() : of(null))),
			tap(user => {
				this._currentUser = user;
			}),
			shareReplay({ bufferSize: 1, refCount: false }),
		);
	}

	/** Refresh user profile. */
	public refreshUserProfile(): Observable<User | null> {
		this._refreshUser$.next();
		return this.currentUser$;
	}

	/**
	 * Check value of user role.
	 * @param role User role.
	 */
	public checkUserRole(role: UserRole | UserRole[]): boolean {
		if (this._currentUser == null) {
			return false;
		}
		if (Array.isArray(role)) {
			return role.includes(this._currentUser.role);
		}
		return this._currentUser.role === role;
	}
}
