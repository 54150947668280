import { StrictOmit } from '@ygm/common/core/utils/types/strict-omit';

import { Option } from '../../models/option';

import { ChamberDto } from './chamber.dto';

/** Campaign dto. */
export interface CampaignDto {

	/** Id. */
	readonly id: number;

	/** Name. */
	readonly name: string;

	/** Status. */
	readonly status: CampaignStatusDto;

	/** Goal. */
	readonly goal: string | null;

	/** Year. */
	readonly year: number;

	/** Chamber id. */
	readonly chamber?: ChamberDto['id'];

	/** Campaign's end date string (format: YYYY-mm-dd). */
	readonly end_date: string | null;

	/** Campaign's end date string (format: YYYY-mm-dd). */
	readonly start_date: string | null;

	/** Report close time. */
	readonly report_close_time: string | null;

	/** Report close on weekday. */
	readonly report_close_weekday: WeekdayDto | null;

	/** Whether campaign allows trades or not. */
	readonly has_trades: boolean;

	/** Whether campaign allows vice chairs or not. */
	readonly has_vice_chairs: boolean;

	/** Pre trc income. */
	readonly pre_trc_income: number;

	/** Timeline. */
	readonly timeline: Option['value'] | null;

	/** Whether the campaign is renewed or not. */
	readonly is_renewed: boolean;

	/** Report timezone. */
	readonly timezone: ReportTimezoneDto | null;

	/** Whether campaign allows SMS contracts. */
	readonly allow_sms_contracts: boolean;

	/** Campaign settings. */
	readonly settings: {

		/** Should the campaign goal be displayed. */
		readonly show_campaign_goal: boolean;

		/** Should the total earnings to date be displayed. */
		readonly show_total_earnings_to_date: boolean;

		/** Whether the campaign uses other type for incentives or not. */
		readonly campaign_uses_other_incentives: boolean;
	};
}

export type CampaignEditDto = StrictOmit<CampaignDto, 'chamber' | 'id' | 'is_renewed' | 'start_date' | 'allow_sms_contracts'>;
export type CampaignCreationDto = Pick<CampaignDto, 'name' | 'year' | 'allow_sms_contracts'> & {
	readonly chamber: ChamberDto['id'];
};

/** Campaign status dto. */
export enum CampaignStatusDto {
	Created = 'created',
	Renewal = 'renewal',
	Live = 'live',
	Closed = 'closed',
	Done = 'done',
}

/** Weekday dto. */
export enum WeekdayDto {
	Monday = 0,
	Tuesday = 1,
	Wednesday = 2,
	Thursday = 3,
	Friday = 4,
	Saturday = 5,
	Sunday = 6,
}

/** Report timezone dto. */
export enum ReportTimezoneDto {
	USCentral = 'US/Central',
	USPacific = 'US/Pacific',
	USMountain = 'US/Mountain',
	USEastern = 'US/Eastern',
}
