import { Injectable } from '@angular/core';

import { UserSecret } from '../../models/user-secret';

import { UserSecretDto } from '../dto/user-secret.dto';

import { IMapperFromDto } from './mappers';
import { USER_ROLE_FROM_DTO_MAP } from './campaign-user-standing.mapper';

/** User secret mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserSecretDataMapper
implements IMapperFromDto<UserSecretDto, UserSecret> {

	/** @inheritdoc */
	public fromDto(dto: UserSecretDto): UserSecret {
		return {
			token: dto.token,
			user: {
				id: dto.user.id,
				role: USER_ROLE_FROM_DTO_MAP[dto.user.role],
			},
		};
	}
}
