import { enumToArray } from '@ygm/common/core/utils/enum-to-array';

/** Campaign user role. */
export enum CampaignUserRole {
	Chair = 'chair',
	ViceChair = 'viceChair',
	TeamCaptain = 'teamCaptain',
	Volunteer = 'volunteer',
}

/** Campaign user role utility functions. */
export namespace CampaignUserRole {
	const TO_READABLE: Record<CampaignUserRole, string> = {
		[CampaignUserRole.Chair]: 'Chair',
		[CampaignUserRole.ViceChair]: 'Vice Chair',
		[CampaignUserRole.TeamCaptain]: 'Team Captain',
		[CampaignUserRole.Volunteer]: 'Volunteer',
	};

	/** Represent CampaignUserRole enum as an array. */
	export function toArray(): CampaignUserRole[] {
		return enumToArray(CampaignUserRole);
	}

	/**
	 * Whether a multiple teams can be assigned to user or not.
	 * @param role Role to check.
	 */
	export function canAssignMultipleTeams(role: CampaignUserRole): boolean {
		return role === CampaignUserRole.ViceChair || role === CampaignUserRole.Chair;
	}

	/**
	 * Provide human-readable value for campaign user role.
	 * @param role Campaign user role.
	 */
	export function toReadable(role: CampaignUserRole): string {
		return TO_READABLE[role];
	}
}
