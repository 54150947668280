import { enumToArray } from '@ygm/common/core/utils/enum-to-array';

/** Weekday. */
export enum Weekday {
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
	Sunday = 'Sunday',
}

export namespace Weekday {
	const TO_READABLE: Record<Weekday, string> = {
		[Weekday.Monday]: 'Monday',
		[Weekday.Tuesday]: 'Tuesday',
		[Weekday.Wednesday]: 'Wednesday',
		[Weekday.Thursday]: 'Thursday',
		[Weekday.Friday]: 'Friday',
		[Weekday.Saturday]: 'Saturday',
		[Weekday.Sunday]: 'Sunday',
	};

	/**
	 * Provide human-readable value for weekday.
	 * @param day Weekday.
	 */
	export function toReadable(day: Weekday): string {
		return TO_READABLE[day];
	}

	/** Get enum values as array. */
	export function toArray(): Weekday[] {
		return enumToArray(Weekday);
	}

	/**
	 * Get num values as array with the US order from Sunday -> Saturday.
	 * Sunday -> Monday -> ... -> Friday -> Saturday.
	 */
	export function toArrayWithUSOrder(): Weekday[] {
		return [
			Weekday.Sunday,
			Weekday.Monday,
			Weekday.Tuesday,
			Weekday.Wednesday,
			Weekday.Thursday,
			Weekday.Friday,
			Weekday.Saturday,
		];
	}
}
