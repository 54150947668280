import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ChamberValidationService, SUBDOMAIN_CHECK_PATH } from '@ygm/common/core/services/chamber-validation.service';
import { CONFIRM_PASSWORD_URL, RESET_PASSWORD_URL } from '@ygm/common/core/services/credentials.service';
import { assertNonNullWithReturn } from '@ygm/common/core/utils/assert-non-null';
import { Observable, first, switchMap } from 'rxjs';

const CHAMBER_ID_PARAM_NAME = 'chamber';
const S3_PATH = 'amazonaws.com';

const EXCLUDE_PATHS = [
	SUBDOMAIN_CHECK_PATH,
	RESET_PASSWORD_URL,
	CONFIRM_PASSWORD_URL,
	S3_PATH,
];

/** Adds chamber id to all requests with `public` api path resource. */
@Injectable()
export class PublicRequestInterceptor implements HttpInterceptor {

	private readonly chamberValidationService = inject(ChamberValidationService);

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (this.shouldIntercept(req.url)) {
			return this.chamberValidationService.chamberInformation$.pipe(
				first(),
				switchMap(chamberInfo => {
					const chamberId = assertNonNullWithReturn(chamberInfo.id);
					const newReq = req.clone({
						params: this.appendChamberParam(req.params, chamberId),
					});
					return next.handle(newReq);
				}),
			);
		}

		// Do nothing.
		return next.handle(req);
	}

	/**
	 * Checks if a request should be intercepted.
	 * @param url - Request url.
	 */
	private shouldIntercept(url: string): boolean {
		return !EXCLUDE_PATHS.some(path => url.includes(path));
	}

	/**
	 * Appends chamber param to a list of `params`.
	 * @param params Requests's params.
	 * @param chamberId Id of current Chamber.
	 */
	private appendChamberParam(params: HttpParams, chamberId: number): HttpParams {
		return params.append(CHAMBER_ID_PARAM_NAME, chamberId.toString());
	}
}
