import { Injectable } from '@angular/core';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import {
	IMapperFromDto,
	IMapperToCreationDto,
	IMapperToEditDto,
	IValidationErrorMapper,
} from '@ygm/common/core/services/mappers/mappers';

import { Member, MemberContact, MemberCreationData, MemberEditData } from '../../models/member';
import { MemberContactDto, MemberContactMergeDto, MemberCreationDto, MemberDto, MemberEditDto, MemberMergeDto } from '../dto/member.dto';
import { MergeData } from '../../models/mergeData';
import { assertNonNull } from '../../utils/assert-non-null';

/** Member mapper. */
@Injectable({
	providedIn: 'root',
})
export class MemberMapper
implements
		IMapperFromDto<MemberDto, Member>,
		IMapperToCreationDto<MemberCreationDto, MemberCreationData>,
		IValidationErrorMapper<MemberDto, Member>,
		IMapperToEditDto<MemberEditDto, MemberEditData> {
	/** @inheritdoc */
	public fromDto(dto: MemberDto): Member {
		return {
			id: dto.id,
			city: dto.city,
			name: dto.name,
			zip: dto.zip,
			state: dto.state,
			address: dto.address,
			contacts: dto.contacts.map(contact => this.fromContactDto(contact)),
			canRemove:
				dto.contracts_count != null && dto.contracts_count === 0 && dto.users_count != null && dto.users_count === 0,
		};
	}

	/** @inheritdoc */
	public toCreationDto(data: MemberCreationData): MemberCreationDto {
		return {
			city: data.city,
			name: data.name,
			zip: data.zip,
			state: data.state,
			address: data.address,
			contacts: data.contacts.map(contact => this.toContactDto(contact)),
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<MemberDto>): EntityValidationErrors<Member> {
		return {
			id: extractErrorMessageByField('id', errorDto),
			city: extractErrorMessageByField('city', errorDto),
			name: extractErrorMessageByField('name', errorDto),
			zip: extractErrorMessageByField('zip', errorDto),
			state: extractErrorMessageByField('state', errorDto),
			address: extractErrorMessageByField('address', errorDto),
			contacts: extractErrorMessageByField('contacts', errorDto),
		};
	}

	/** @inheritdoc */
	public toEditDto(data: MemberEditData): MemberEditDto {
		return {
			city: data.city,
			name: data.name,
			zip: data.zip,
			state: data.state,
			address: data.address,
			contacts: data.contacts.map(contact => this.toContactDto(contact)),
		};
	}

	/**
	 * Maps contact data to dto.
	 * @param data Contact data.
	 */
	public toContactDto(data: MemberContact): MemberContactDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			mobile_phone: data.mobilePhone,
			work_phone: data.workPhone,
			id: data.id,
		};
	}

	/**
	 * Maps contact data from dto.
	 * @param dto Contact dto.
	 */
	public fromContactDto(dto: MemberContactDto): MemberContact {
		return {
			firstName: dto.first_name,
			lastName: dto.last_name,
			email: dto.email,
			mobilePhone: dto.mobile_phone,
			workPhone: dto.work_phone,
			id: dto.id,
		};
	}

	/**
	 * Map member merge data to contract merge dto.
	 * @param data Member merge model.
	 */
	public toMemberMergeDto(data: MergeData<Member>): MemberMergeDto {
		return {
			source_member_ids: data.sources.map(({ id }) => id),
		};
	}

	/**
	 * Map member contact merge data to dto.
	 * @param data Member contact merge model.
	 */
	public toMemberContactMergeDto(data: MergeData<MemberContact>): MemberContactMergeDto {
		assertNonNull(data.original.id);
		return {
			dest_contact_id: data.original.id,
			source_contact_ids: data.sources.map(({ id }) => {
				assertNonNull(id);
				return id;
			}),
		};
	}
}
