import { Injectable } from '@angular/core';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';

import { assertNonNull } from '@ygm/common/core/utils/assert-non-null';

import { VolunteerRegistrationData, VolunteerRegistrationInfo, VolunteerRegistrationParams } from '../../models/volunteer-registration-data';
import { VolunteerRegistrationDto, VolunteerRegistrationInfoDto, VolunteerRegistrationParamsDto } from '../dto/volunteer-registration.dto';

/** Registration data mapper. */
@Injectable({
	providedIn: 'root',
})
export class RegistrationDataMapper implements IMapperToDto<VolunteerRegistrationDto, VolunteerRegistrationData>,
 IValidationErrorMapper<VolunteerRegistrationDto, VolunteerRegistrationData> {

	/** @inheritdoc */
	public toDto(data: VolunteerRegistrationData): VolunteerRegistrationDto {
		assertNonNull(data.chamberId);
		return {
			token: data.token,
			uid: data.uid,
			chamber_id: data.chamberId,
			password: data.password,
			password_confirm: data.passwordConfirm,
		};
	}

	/**
	 * Maps registration info from dto.
	 * @param data Registration info data dto.
	 */
	public fromRegistrationInfo(data: VolunteerRegistrationInfoDto): VolunteerRegistrationInfo {
		return {
			email: data.email,
			firstName: data.first_name,
			lastName: data.last_name,
		};
	}

	/**
	 * Maps registration info params to dto.
	 * @param data Registration params.
	 */
	public registrationInfoParamsToDto(data: VolunteerRegistrationParams): VolunteerRegistrationParamsDto {
		assertNonNull(data.chamberId);
		return {
			uid: data.uid,
			token: data.token,
			chamber_id: data.chamberId,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<VolunteerRegistrationDto>,
	): EntityValidationErrors<VolunteerRegistrationData> {
		return {
			password: extractErrorMessageByField('password', errorDto),
			passwordConfirm: extractErrorMessageByField('password_confirm', errorDto),
		};
	}
}
