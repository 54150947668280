import { Injectable, inject } from '@angular/core';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperFromDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { Campaign, CampaignCreationData, CampaignStatus } from '../../models/campaign';
import { CampaignCreationDto, CampaignDto, CampaignStatusDto } from '../dto/campaign.dto';

import { REPORT_TIMEZONE_MAP_FROM_DTO, WEEKDAY_MAP_FROM_DTO } from './campaign-edit.mapper';
import { CampaignReportTimeMapper } from './campaign-report-time.mapper';

export const CAMPAIGN_STATUS_FROM_DTO_MAP: Readonly<Record<CampaignStatusDto, CampaignStatus>> = {
	[CampaignStatusDto.Created]: CampaignStatus.Created,
	[CampaignStatusDto.Done]: CampaignStatus.Done,
	[CampaignStatusDto.Live]: CampaignStatus.Live,
	[CampaignStatusDto.Renewal]: CampaignStatus.Renewal,
	[CampaignStatusDto.Closed]: CampaignStatus.Closed,
};

export const CAMPAIGN_STATUS_TO_DTO_MAP = reverseRecord(CAMPAIGN_STATUS_FROM_DTO_MAP);

/** Campaign mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignMapper implements IMapperFromDto<CampaignDto, Campaign>,
 IValidationErrorMapper<CampaignCreationDto, CampaignCreationData> {

	private readonly campaignTimeMapper = inject(CampaignReportTimeMapper);

	/** @inheritdoc */
	public fromDto(dto: CampaignDto): Campaign {
		return {
			id: dto.id,
			status: CAMPAIGN_STATUS_FROM_DTO_MAP[dto.status],
			goal: dto.goal ? Number(dto.goal) : null,
			name: dto.name,
			year: dto.year,
			chamberId: dto.chamber,
			endDate: dto.end_date,
			startDate: dto.start_date,
			reportCloseTime: dto.report_close_time ? this.campaignTimeMapper.fromDto(dto.report_close_time) : null,
			reportCloseWeekday: dto.report_close_weekday != null ? WEEKDAY_MAP_FROM_DTO[dto.report_close_weekday] : null,
			timeline: dto.timeline,
			preTrcIncome: dto.pre_trc_income,
			hasTrades: dto.has_trades,
			hasViceChairs: dto.has_vice_chairs,
			isRenewed: dto.is_renewed,
			timezone: dto.timezone ? REPORT_TIMEZONE_MAP_FROM_DTO[dto.timezone] : null,
			allowSmsContracts: dto.allow_sms_contracts,
			settings: {
				shouldShowCampaignGoal: dto.settings.show_campaign_goal,
				shouldShowTotalEarningsToDate: dto.settings.show_total_earnings_to_date,
				campaignUsesOtherIncentives: dto.settings.campaign_uses_other_incentives,
			},
		};
	}

	/**
	 * Maps campaign to creation dto.
	 * @param data Creation data.
	 */
	public toCreationDto(data: CampaignCreationData): CampaignCreationDto {
		return {
			chamber: data.chamberId,
			name: data.name,
			year: data.year,
			allow_sms_contracts: data.allowSmsContracts,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<CampaignCreationDto>): EntityValidationErrors<CampaignCreationData> {
		return {
			name: extractErrorMessageByField('name', errorDto),
			year: extractErrorMessageByField('year', errorDto) ?? extractErrorMessageByField('status', errorDto),
		};
	}
}
