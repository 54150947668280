import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';

import { MeridiemTimeString } from '../../utils/types/meridiem-time';

import { IMapper } from './mappers';

/** Campaign report time mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignReportTimeMapper implements IMapper<string, MeridiemTimeString> {
	/** @inheritdoc */
	public fromDto(date: string): MeridiemTimeString {
		return DateTime.fromFormat(date, 'HH:mm:ss').toFormat('h:mm a') as MeridiemTimeString;
	}

	/** @inheritdoc */
	public toDto(date: MeridiemTimeString): string {
		return DateTime.fromFormat(date, 'h:mm a').toFormat('HH:mm:ss');
	}
}
