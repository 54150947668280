/** User role. */
export enum UserRole {
	ChamberChair = 'chamberChair',
	ViceChair = 'viceChair',
	TeamCaptain = 'teamCaptain',
	Volunteer = 'volunteer',

	// These user campaign roles exist because Super Admins and Chamber Admins can sign in to the Volunteers app,
	// so they should only be used for this scenario.
	SuperAdmin = 'superAdmin',
	ChamberAdmin = 'chamberAdmin',
}

export namespace UserRole {
	const TO_READABLE: Record<UserRole, string> = {
		[UserRole.SuperAdmin]: 'YGM',
		[UserRole.ChamberAdmin]: 'Chamber Admin',
		[UserRole.Volunteer]: 'Volunteer',
		[UserRole.ChamberChair]: 'Chair',
		[UserRole.ViceChair]: 'Vice Chair',
		[UserRole.TeamCaptain]: 'Team Captain',
	};

	/**
	 * Provide human-readable value for user role.
	 * @param role User role.
	 */
	export function toReadable(role: UserRole): string {
		return TO_READABLE[role];
	}
}
