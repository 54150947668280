import { Injectable, inject } from '@angular/core';
import { Observable, Subject, defer, filter, fromEvent, map, merge, of, shareReplay, startWith } from 'rxjs';

import { WINDOW } from '../utils/window-token';
import { APP_MODE } from '../utils/app-mode';

const ADMIN_APP_STORAGE_KEY_PREFIX = 'admin-';
const PUBLIC_APP_STORAGE_KEY_PREFIX = 'public-';

/**
 * Storage service. Uses `localStorage` underhood.
 */
@Injectable({
	providedIn: 'root',
})
export abstract class StorageService {

	/** Application mode. */
	protected readonly appMode = inject(APP_MODE);

	/** Emits the key of the changed value. */
	private readonly valueChangedSubject = new Subject<string>();

	private readonly storage: Storage;

	private readonly window = inject(WINDOW);

	public constructor(windowStorage: Storage) {
		this.storage = windowStorage;
	}

	/**
	 * Save data to storage.
	 * @param key Key.
	 * @param data Data for save.
	 * @param shouldUseAdminPrefix Whether should use the admin app storage key.
	 */
	public save<T>(key: string, data: T, shouldUseAdminPrefix = false): Observable<void> {
		const keyPrefix = shouldUseAdminPrefix ? ADMIN_APP_STORAGE_KEY_PREFIX : this.keyPrefix;
		const storageKey = keyPrefix.concat(key);
		return defer(() => {
			this.storage.setItem(storageKey, JSON.stringify(data));

			this.storage.getItem(storageKey);
			this.valueChangedSubject.next(storageKey);

			return of(undefined);
		});
	}

	/**
	 * Save data to storage synchronously.
	 * @param key Key.
	 * @param data Data for save.
	 * @param shouldUseAdminPrefix Whether should use the admin app storage key.
	 */
	public saveSync<T>(key: string, data: T, shouldUseAdminPrefix = false): void {
		const keyPrefix = shouldUseAdminPrefix ? ADMIN_APP_STORAGE_KEY_PREFIX : this.keyPrefix;
		const storageKey = keyPrefix.concat(key);
		this.storage.setItem(storageKey, JSON.stringify(data));

		this.storage.getItem(storageKey);
		this.valueChangedSubject.next(storageKey);
	}

	/**
	 * Get item from storage by key.
	 * @param key Key.
	 */
	public get<T = unknown>(key: string): Observable<T | null> {
		const storageKey = this.keyPrefix.concat(key);
		return this.watchStorageChangeByKey(storageKey).pipe(
			map(() => this.obtainFromStorageByKey<T>(storageKey)),
			startWith(this.obtainFromStorageByKey<T>(storageKey)),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
	}

	private watchStorageChangeByKey(keyToWatch: string): Observable<void> {
		const otherPageChange$ = fromEvent(this.window, 'storage').pipe(
			filter((event): event is StorageEvent => event instanceof StorageEvent),
			map(event => event.key),
		);

		// storage event happens only for the other pages of this domain, so we need to handle the local changes manually
		// https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
		const currentPageChange$ = this.valueChangedSubject;

		return merge(
			otherPageChange$,
			currentPageChange$,
		).pipe(
			filter(key => key === keyToWatch),
			map(() => undefined),
		);
	}

	/**
	 * Obtains storage value by provided key.
	 * @param key Key.
	 */
	protected obtainFromStorageByKey<T = unknown>(key: string): T | null {
		const rawData = this.storage.getItem(key);
		if (rawData == null) {
			return null;
		}
		return JSON.parse(rawData) as T;
	}

	/**
	 * Removed data from storage.
	 * @param key Key.
	 */
	public remove(key: string): Observable<void> {
		const storageKey = this.keyPrefix.concat(key);
		return defer(() => {
			this.storage.removeItem(storageKey);
			this.valueChangedSubject.next(storageKey);

			return of(undefined);
		});
	}

	/** Get key prefix based on app mode. */
	protected get keyPrefix(): string {
		if (this.appMode === 'public') {
			return PUBLIC_APP_STORAGE_KEY_PREFIX;
		}
		return ADMIN_APP_STORAGE_KEY_PREFIX;
	}
}
