import { inject, Injectable } from '@angular/core';
import {
	IMapperFromDetailsDto,
	IMapperFromDto,
	IMapperToCreationDto,
	IMapperToEditDto,
	IValidationErrorMapper,
} from '@ygm/common/core/services/mappers/mappers';

import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import {
	Level,
	LevelCreationData,
	LevelInstance,
	LevelInstanceCreationData,
	LevelReport,
	MarketingLevel,
	PurchasingMember,
} from '@ygm/common/core/models/inventory/level';
import {
	LevelDto,
	LevelInstanceCreationDto,
	LevelInstanceDto,
	MarketingLevelDto,
	PurchasingMemberDto,
} from '@ygm/common/core/services/dto/level.dto';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import {
	LevelCreationDto,
	LevelEditDto,
	LevelReportDto,
} from 'projects/admin-web/src/app/core/services/dto/inventory/level.dto';

import { CampaignUserMapper } from './campaign-user.mapper';
import { ContractTypeMapper } from './contract-type.mapper';

/** Level mapper. */
@Injectable({
	providedIn: 'root',
})
export class LevelMapper
implements
		IMapperFromDto<LevelDto, Level>,
		IMapperFromDetailsDto<MarketingLevelDto, MarketingLevel>,
		IMapperToCreationDto<LevelCreationDto, LevelCreationData>,
		IMapperToEditDto<LevelEditDto, Level>,
		IValidationErrorMapper<LevelCreationDto, LevelCreationData> {

	private readonly campaignUserMapper = inject(CampaignUserMapper);

	private readonly contractTypeMapper = inject(ContractTypeMapper);

	/** @inheritdoc */
	public fromDto(dto: LevelDto): Level {
		return {
			id: dto.id,
			name: dto.name,
			productId: dto.product_id,
			cost: Number(dto.cost),
			instanceCount: dto.amount,
			benefits: dto.benefits,
			conditions: dto.conditions,
			description: dto.description,
			productName: dto.product_name,
			totalInstancesCount: dto.amount ?? null,
			salesRevenue: dto.sales_revenue ?? null,
			isNotIncludedInNextRenewal: !dto.is_included_in_next_renewal,
			isNotIncludedInCurrentRenewal: !dto.is_included_in_current_renewal,
		};
	}

	/** @inheritdoc */
	public fromDetailsDto(dto: MarketingLevelDto): MarketingLevel {
		return {
			...this.fromDto(dto),
			remainingInstancesCount: dto.remaining_instances_count,
			soldInstancesCount: dto.sold_instances_count,
			purchasingMembers:
				dto.members_purchased != null ?
					dto.members_purchased.map(member => this.fromPurchasingMemberDto(member)) :
					[],
		};
	}

	/**
	 * Maps levels instance from dto.
	 * @param dto Level instance dto.
	 */
	public fromInstanceDto(dto: LevelInstanceDto): LevelInstance {
		return {
			...this.fromDto(dto),
			levelId: dto.level_id,
			declinedAt: dto.declined_at != null ? new Date(dto.declined_at) : null,
			createdAt: new Date(dto.created),
			tradeWith: dto.trade_with,
			contract:
				dto.contract != null ?
					{
						id: dto.contract.id,
						name: dto.contract.name,
						approvedAt: dto.contract.approved_at == null ? null : new Date(dto.contract.approved_at),
						member: {
							id: dto.contract.member.id,
							name: dto.contract.member.name,
						},
						createdBy: this.campaignUserMapper.fromDto(dto.contract.created_by),
						type: this.contractTypeMapper.fromDto(dto.contract.type),
					} :
					null,
		};
	}

	/**
	 * Maps levels instance to dto.
	 * @param data Level instance creation data.
	 */
	public toInstanceCreationDto(data: LevelInstanceCreationData): LevelInstanceCreationDto {
		return {
			id: data.id,
			level_id: data.levelId,
			trade_with: data.tradeWith,
			cost: data.cost,
			is_declined: false,
		};
	}

	private fromPurchasingMemberDto(memberDto: PurchasingMemberDto): PurchasingMember {
		return {
			id: memberDto.id,
			name: memberDto.name,
			purchasedValue: memberDto.purchased_value,
			title: memberDto.title,
		};
	}

	/** @inheritdoc */
	public toCreationDto(data: LevelCreationData): LevelCreationDto {
		return {
			product: data.productId,
			description: data.description,
			conditions: data.conditions,
			amount: data.instanceCount,
			cost: data.cost,
			name: data.name,
			benefits: data.benefits,
			is_included_in_current_renewal: !data.isNotIncludedInCurrentRenewal,
			is_included_in_next_renewal: !data.isNotIncludedInNextRenewal,
		};
	}

	/** @inheritdoc */
	public toEditDto(data: Level): LevelEditDto {
		return {
			product: data.productId,
			description: data.description,
			conditions: data.conditions,
			amount: data.instanceCount,
			cost: data.cost,
			name: data.name,
			benefits: data.benefits,
			is_included_in_current_renewal: !data.isNotIncludedInCurrentRenewal,
			is_included_in_next_renewal: !data.isNotIncludedInNextRenewal,
		};
	}

	/**
	 * Maps level report from dto.
	 * @param dto Level report dto.
	 */
	public fromReportDto(dto: LevelReportDto): LevelReport {
		return {
			id: dto.id,
			name: dto.name,
			cost: Number(dto.cost),
			soldInstancesCount: dto.sold_instances_count,
			soldValue: dto.sold_value,
			remainingValue: dto.remaining_value,
			totalInstancesCount: dto.total_instances_count,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<LevelCreationDto>,
	): EntityValidationErrors<LevelCreationData> {
		return {
			productId: extractErrorMessageByField('product', errorDto),
			description: extractErrorMessageByField('description', errorDto),
			cost: extractErrorMessageByField('cost', errorDto),
			conditions: extractErrorMessageByField('conditions', errorDto),
			instanceCount: extractErrorMessageByField('amount', errorDto),
			name: extractErrorMessageByField('name', errorDto),
			benefits: extractErrorMessageByField('benefits', errorDto),
		};
	}
}
