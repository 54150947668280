import { enumToArray } from '@ygm/common/core/utils/enum-to-array';

/** User contact method. */
export enum UserContactMethod {
	Email = 'email',
	Mobile = 'mobile',
	Voice = 'voice',
}

export namespace UserContactMethod {
	const TO_READABLE: Record<UserContactMethod, string> = {
		[UserContactMethod.Voice]: 'Voice',
		[UserContactMethod.Mobile]: 'Mobile / Text',
		[UserContactMethod.Email]: 'Email',
	};

	/** Represent UserContactMethod enum as an array. */
	export function toArray(): UserContactMethod[] {
		return enumToArray(UserContactMethod);
	}

	/**
	 * Provide human-readable value for user contact method.
	 * @param contactMethod Contact method.
	 */
	export function toReadable(contactMethod: UserContactMethod): string {
		return TO_READABLE[contactMethod];
	}
}
