import { Injectable, inject } from '@angular/core';
import { IMapperFromDto } from '@ygm/common/core/services/mappers/mappers';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { USER_ROLE_FROM_DTO_MAP } from '@ygm/common/core/services/mappers/campaign-user-standing.mapper';

import { User, ProfileEditData, UserPreference } from '../../models/user/user';
import { UserContactMethod } from '../../models/user/user-contact-method';
import { UserContactMethodDto } from '../dto/user/user-contact-method.dto';
import { UserDto, ProfileEditDto, UserPreferenceDto } from '../dto/user/user.dto';

import { TeamMapper } from './team.mapper';

const USER_CONTRACT_METHOD_FROM_DTO_MAP: Readonly<Record<UserContactMethodDto, UserContactMethod>> = {
	[UserContactMethodDto.Voice]: UserContactMethod.Voice,
	[UserContactMethodDto.Mobile]: UserContactMethod.Mobile,
	[UserContactMethodDto.Email]: UserContactMethod.Email,
};

const USER_CONTRACT_METHOD_TO_DTO_MAP = reverseRecord(USER_CONTRACT_METHOD_FROM_DTO_MAP);

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserMapper implements IMapperFromDto<UserDto, User> {

	private readonly teamMapper = inject(TeamMapper);

	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return {
			id: dto.id,
			userId: dto.user_id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			email: dto.email,
			role: USER_ROLE_FROM_DTO_MAP[dto.role],
			avatar: dto.avatar,
			mobilePhone: dto.mobile_phone,
			birthday: dto.birthday,
			homeAddress: dto.home_address,
			homeCity: dto.home_city,
			homeState: dto.home_state,
			homeZipCode: dto.home_zip_code,
			memberId: dto.member,
			title: dto.title,
			companyName: dto.company_name,
			companyAddress: dto.company_address,
			companyCity: dto.company_city,
			companyState: dto.company_state,
			companyZipCode: dto.company_zip_code,
			workPhone: dto.work_phone,
			preferredContactMethods: dto.preferred_contact_methods.map(contactMethod => USER_CONTRACT_METHOD_FROM_DTO_MAP[contactMethod]),
			instagramUrl: dto.preference.instagram_url,
			facebookUrl: dto.preference.facebook_url,
			twitterUrl: dto.preference.twitter_url,
			linkedinUrl: dto.preference.linkedin_url,
			...this.fromPreferenceDto(dto.preference),
			team: dto.team !== null ? this.teamMapper.fromDto(dto.team) : null,
			isCloaked: dto.is_cloaked,
		};
	}

	/** @inheritdoc */
	public toEditDto(data: ProfileEditData): ProfileEditDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			birthday: data.birthday,
			email: data.email,
			home_address: data.homeAddress,
			home_city: data.homeCity,
			home_state: data.homeState,
			home_zip_code: data.homeZipCode,
			mobile_phone: data.mobilePhone,
			member: data.memberId,
			title: data.title,
			company_name: data.companyName,
			company_address: data.companyAddress,
			company_city: data.companyCity,
			company_state: data.companyState,
			company_zip_code: data.companyZipCode,
			work_phone: data.workPhone,
			preferred_contact_methods: data.preferredContactMethods.map(contactMethod => USER_CONTRACT_METHOD_TO_DTO_MAP[contactMethod]),
			preference: this.toPreferenceDto(data),
		};
	}

	private fromPreferenceDto(dto: UserPreferenceDto): UserPreference {
		return {
			favoriteCandy: dto.favorite_candy,
			favoriteDrink: dto.favorite_drink,
			favoriteMovie: dto.favorite_movie,
			favoriteRestaurant: dto.favorite_restaurant,
			hobbies: dto.hobbies,
		};
	}

	private toPreferenceDto(data: ProfileEditData): UserPreferenceDto {
		return {
			favorite_candy: data.favoriteCandy,
			favorite_drink: data.favoriteDrink,
			favorite_movie: data.favoriteMovie,
			favorite_restaurant: data.favoriteRestaurant,
			hobbies: data.hobbies,
			instagram_url: data.instagramUrl,
			facebook_url: data.facebookUrl,
			twitter_url: data.twitterUrl,
			linkedin_url: data.linkedinUrl,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<UserDto>):
	EntityValidationErrors<User> {
		return {
			title: extractErrorMessageByField('title', errorDto),
			companyName: extractErrorMessageByField('company_name', errorDto),
			companyAddress: extractErrorMessageByField('company_address', errorDto),
			companyCity: extractErrorMessageByField('company_city', errorDto),
			companyState: extractErrorMessageByField('company_state', errorDto),
			companyZipCode: extractErrorMessageByField('company_zip_code', errorDto),
			mobilePhone: extractErrorMessageByField('mobile_phone', errorDto),
			workPhone: extractErrorMessageByField('work_phone', errorDto),
			preferredContactMethods: extractErrorMessageByField('preferred_contact_methods', errorDto),
			birthday: extractErrorMessageByField('birthday', errorDto),
			facebookUrl: extractErrorMessageByField('preference.facebook_url', errorDto),
			twitterUrl: extractErrorMessageByField('preference.twitter_url', errorDto),
			linkedinUrl: extractErrorMessageByField('preference.linkedin_url', errorDto),
			instagramUrl: extractErrorMessageByField('preference.instagram_url', errorDto),
		};
	}
}
