import { Injectable } from '@angular/core';

import { BaseFilterParams } from '../../models/base-filter-params';

import { BaseFilterParamsDto } from '../dto/base-filter-params.dto';

import { IMapperToDto } from './mappers';

/** Base filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class BaseFilterParamsMapper implements
  IMapperToDto<BaseFilterParamsDto.Combined, BaseFilterParams.Combined> {

	/** @inheritdoc */
	public toDto(data: BaseFilterParams.Combined): BaseFilterParamsDto.Combined {
		return {
			...this.toSearchFilterDto(data),
			...this.toPaginationFilterDto(data),
		};
	}

	/** @inheritdoc */
	public toSearchFilterDto(data: BaseFilterParams.Search): BaseFilterParamsDto.SearchDto {
		return {
			search: data.search,
		};
	}

	/** @inheritdoc */
	public toPaginationFilterDto(data: BaseFilterParams.Pagination): BaseFilterParamsDto.PaginationDto {
		return {
			limit: data.pageSize,
			offset: data.pageNumber * data.pageSize,
		};
	}
}
