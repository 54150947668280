import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';

/** Guard prevents user from accessing pages the site's subdomain is wrong. */
export function subdomainGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);

	return chamberValidationService.isSubdomainValid$.pipe(
		map(isSubdomainValid => isSubdomainValid ? true : router.parseUrl(routePaths.notFound.path)),
		first(),
	);
}
