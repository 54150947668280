import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AppErrorMapper } from '@ygm/common/core/services/mappers/app-error.mapper';

import { User, ProfileEditData } from '../models/user/user';

import { UserMapper } from './mappers/user.mapper';
import { PublicAppUrlsConfig } from './public-app-urls.config';
import { UserDto, BusinessInformationDto } from './dto/user/user.dto';

/** Performs CRUD operations for users. */
@Injectable({
	providedIn: 'root',
})
export class UserApiService {

	private readonly apiUrls = inject(PublicAppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly userMapper = inject(UserMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	/** Returns current user info.*/
	public getCurrentUser(): Observable<User> {
		return this.httpClient.get<UserDto>(this.apiUrls.user.currentProfile()).pipe(
			map(userDto => this.userMapper.fromDto(userDto)),
		);
	}

	/**
	 * Edits user profile.
	 * @param data User edit data.
	 */
	public edit(data: ProfileEditData): Observable<void> {
		const body = this.userMapper.toEditDto(data);

		return this.httpClient.put<BusinessInformationDto>(this.apiUrls.user.currentProfile(), body).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.userMapper),
			map(() => undefined),
		);
	}
}
