import { enumToArray } from '../utils/enum-to-array';

/** Report timezone. */
export enum ReportTimezone {
	USCentral = 'US/Central',
	USPacific = 'US/Pacific',
	USMountain = 'US/Mountain',
	USEastern = 'US/Eastern',
}

export namespace ReportTimezone {
	const TO_READABLE: Record<ReportTimezone, string> = {
		[ReportTimezone.USCentral]: 'US/Central',
		[ReportTimezone.USEastern]: 'US/Eastern',
		[ReportTimezone.USMountain]: 'US/Mountain',
		[ReportTimezone.USPacific]: 'US/Pacific',
	};

	/**
	 * Provide human-readable value for report timezone.
	 * @param timezone Timezone.
	 */
	export function toReadable(timezone: ReportTimezone): string {
		return TO_READABLE[timezone];
	}

	/** Get enum values as array. */
	export function toArray(): ReportTimezone[] {
		return enumToArray(ReportTimezone);
	}
}
