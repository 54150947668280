import { Injectable } from '@angular/core';
import { IMapperFromDto } from '@ygm/common/core/services/mappers/mappers';
import { CAMPAIGN_USER_ROLE_FROM_DTO_MAP } from '@ygm/common/core/services/mappers/campaign-user-role.mapper';

import { CampaignUserLeadershipStandingData, CampaignUserStandingData, CampaignUserTeamStandingData, TeamStatisticsData } from '../../models/campaign/campaign-user-standing';
import { CampaignUserLeadershipStandingDto, CampaignUserStandingDto, CampaignUserTeamStandingDto, TeamStatisticsDto } from '../dto/campaign-user-standing.dto';
import { UserRoleDto } from '../dto/user-role.dto';
import { UserRole } from '../../models/user-role';
import { reverseRecord } from '../../utils/reverse-record';

export const USER_ROLE_FROM_DTO_MAP: Readonly<Record<UserRoleDto, UserRole>> = {
	[UserRoleDto.ChamberChair]: UserRole.ChamberChair,
	[UserRoleDto.ViceChair]: UserRole.ViceChair,
	[UserRoleDto.TeamCaptain]: UserRole.TeamCaptain,
	[UserRoleDto.Volunteer]: UserRole.Volunteer,
	[UserRoleDto.SuperAdmin]: UserRole.SuperAdmin,
	[UserRoleDto.ChamberAdmin]: UserRole.ChamberAdmin,
};

export const USER_ROLE_TO_DTO_MAP = reverseRecord(USER_ROLE_FROM_DTO_MAP);

/** Campaign user standing mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignUserStandingMapper implements IMapperFromDto<CampaignUserStandingDto, CampaignUserStandingData> {

	/** @inheritdoc */
	public fromDto(dto: CampaignUserStandingDto): CampaignUserStandingData {
		return {
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			avatar: dto.avatar,
			companyName: dto.company_name,
			role: CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role],
			totalCashRevenue: dto.total_cash_revenue,
			totalTradeRevenue: dto.total_trade_revenue,
			totalRevenue: dto.total_revenue,
			weekRevenue: dto.week_revenue,
			totalOtherRevenue: dto.total_other_revenue,
		};
	}

	/**
	 * Maps leadership standing from dto.
	 * @param dto Leadership standing dto.
	 */
	public fromLeadershipStandingDto(dto: CampaignUserLeadershipStandingDto): CampaignUserLeadershipStandingData {
		return {
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			role: CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role],
			totalCashRevenue: dto.total_cash_revenue,
			totalRevenue: dto.total_revenue,
			weekRevenue: dto.week_revenue,
		};
	}

	/**
	 * Maps team standing from dto.
	 * @param dto Team standing dto.
	 */
	public fromTeamStandingDto(dto: CampaignUserTeamStandingDto): CampaignUserTeamStandingData {
		return {
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			role: CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role],
			managedTeams: dto.managed_teams.map(team => this.fromTeamStatisticsDto(team)),
		};
	}

	private fromTeamStatisticsDto(dto: TeamStatisticsDto): TeamStatisticsData {
		return {
			id: dto.id,
			name: dto.name,
			goal: dto.goal,
			totalCashRevenue: dto.total_cash_revenue,
			totalRevenue: dto.total_revenue,
			weekRevenue: dto.week_revenue,
			other: dto.total_other_revenue,
		};
	}
}
