import { Injectable } from '@angular/core';
import { IMapperToDto } from '@ygm/common/core/services/mappers/mappers';

import { ContractSignData } from '../../../models/contract/contract-sign-data';
import { ContractSignDto } from '../../dto/contract/contract-sign.dto';

/** Contract sign data mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractSignMapper implements IMapperToDto<ContractSignDto, ContractSignData> {

	/** @inheritdoc */
	public toDto(data: ContractSignData): ContractSignDto {
		return {
			signature: data.signature,
			level_ids: data.levelIds,
		};
	}
}
