import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Pagination } from '@ygm/common/core/models/pagination';
import { PaginationDto } from '@ygm/common/core/services/dto/pagination.dto';
import { AppErrorMapper } from '@ygm/common/core/services/mappers/app-error.mapper';
import { ContractActionValidationMapper } from '@ygm/common/core/services/mappers/contract-action-validation.mapper';
import { PaginationMapper } from '@ygm/common/core/services/mappers/pagination.mapper';
import { composeHttpParams } from '@ygm/common/core/utils/compose-http-params';
import { Observable, map } from 'rxjs';
import { Level } from '@ygm/common/core/models/inventory/level';

import { Contract, ContractCreationData, ContractDetails, ContractEditData } from '../models/contract/contract';
import { ContractFilterParams } from '../models/contract/contract-filter-params';
import { ContractSignData } from '../models/contract/contract-sign-data';

import { ContractDetailsDto, ContractDto } from './dto/contract/contract.dto';
import { ContractFilterParamsMapper } from './mappers/contract/contract-filter-params.mapper';
import { ContractSignMapper } from './mappers/contract/contract-sign.mapper';
import { ContractMapper } from './mappers/contract/contract.mapper';
import { PublicAppUrlsConfig } from './public-app-urls.config';

const DEFAULT_CONTRACT_FILTER_PARAMS: ContractFilterParams = {
	pageNumber: 0,
	pageSize: 10,
	search: '',
	isRenewed: null,
	status: '',
	sortBy: null,
	sortDirection: null,
};

/** Contract service. */
@Injectable({
	providedIn: 'root',
})
export class ContractService {
	private readonly httpClient = inject(HttpClient);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly appUrlsConfig = inject(PublicAppUrlsConfig);

	private readonly paginationMapper = inject(PaginationMapper);

	private readonly contractMapper = inject(ContractMapper);

	private readonly contractSignMapper = inject(ContractSignMapper);

	private readonly contractFilterParamsMapper = inject(ContractFilterParamsMapper);

	private readonly contractActionValidationMapper = inject(ContractActionValidationMapper);

	/**
	 * Gets contracts list.
	 * @param filterParams Filter params.
	 */
	public getList(filterParams: ContractFilterParams): Observable<Pagination<Contract>> {
		const filtersDto = this.contractFilterParamsMapper.toDto(filterParams);

		const params = composeHttpParams(filtersDto);

		return this.httpClient.get<PaginationDto<ContractDto>>(this.appUrlsConfig.contract.list, { params }).pipe(
			map(dto => this.paginationMapper.mapPaginationFromDto(dto, this.contractMapper)),
		);
	}

	/** Checks whether volunteer has any renewal contracts or not. */
	public hasRenewalContracts(): Observable<boolean> {
		const filtersDto = this.contractFilterParamsMapper.toDto({
			...DEFAULT_CONTRACT_FILTER_PARAMS,
			isRenewed: true,
		});

		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<PaginationDto<ContractDto>>(this.appUrlsConfig.contract.list, { params }).pipe(
			map(dto => this.paginationMapper.mapPaginationFromDto(dto, this.contractMapper)),
			map(page => page.totalCount > 0),
		);
	}

	/**
	 * Gets contract by id.
	 * @param id Contract id.
	 */
	public getById(id: Contract['id']): Observable<Contract> {
		return this.httpClient.get<ContractDto>(this.appUrlsConfig.contract.entity(id)).pipe(
			map(dto => this.contractMapper.fromDto(dto)),
		);
	}

	/**
	 * Gets contract details.
	 * @param id Contract id.
	 */
	public getDetails(id: Contract['id']): Observable<ContractDetails> {
		return this.httpClient.get<ContractDetailsDto>(this.appUrlsConfig.contract.internalView(id)).pipe(
			map(dto => this.contractMapper.fromDetailsDto(dto)),
		);
	}

	/**
	 * Creates a contract.
	 * @param data Contract creation data.
	 */
	public create(data: ContractCreationData): Observable<void> {
		const body = this.contractMapper.toCreationDto(data);

		return this.httpClient.post(this.appUrlsConfig.contract.list, body).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractMapper),
			map(() => undefined),
		);
	}

	/**
	 * Edits a contract.
	 * @param id Contract id.
	 * @param data Contract edit data.
	 */
	public edit(id: Contract['id'], data: ContractEditData): Observable<void> {
		const body = this.contractMapper.toEditDto(data);

		return this.httpClient.put(this.appUrlsConfig.contract.entity(id), body).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractMapper),
			map(() => undefined),
		);
	}

	/**
	 * Deletes a contract.
	 * @param id Contract id.
	 */
	public delete(id: Contract['id']): Observable<void> {
		return this.httpClient.delete(this.appUrlsConfig.contract.entity(id)).pipe(
			map(() => undefined),
		);
	}

	/**
	 * Gets contract public view details.
	 * @param token Contract token.
	 */
	public getPublicViewDetails(token: string): Observable<ContractDetails> {
		return this.httpClient.get<ContractDetailsDto>(this.appUrlsConfig.contract.publicView(token)).pipe(
			map(dto => this.contractMapper.fromDetailsDto(dto)),
		);
	}

	/**
	 * Adds level to the contract.
	 * @param contractId Contract's id.
	 * @param levelId Level to add id.
	 */
	public addLevel(contractId: Contract['id'], levelId: Level['id']): Observable<void> {
		const body = { level: levelId };

		return this.httpClient.post(this.appUrlsConfig.contract.attachLevel(contractId), body).pipe(
			map(() => undefined),
		);
	}

	/**
	 * Signs a contract.
	 * @param token Contract token.
	 * @param signData Contract sign data.
	 */
	public sign(token: string, signData: ContractSignData): Observable<void> {
		const body = this.contractSignMapper.toDto(signData);

		return this.httpClient.post(this.appUrlsConfig.contract.sign(token), body).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractActionValidationMapper),
			map(() => undefined),
		);
	}
}
