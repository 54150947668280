import { Injectable } from '@angular/core';
import { IMapper } from '@ygm/common/core/services/mappers/mappers';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { ContractTypeDto } from '../../../../../../public-web/src/app/core/services/dto/contract/contract-type.dto';
import { ContractType } from '../../models/contract-type';

const FROM_DTO_MAP: Readonly<Record<ContractTypeDto, ContractType>> = {
	[ContractTypeDto.Cash]: ContractType.Cash,
	[ContractTypeDto.Trade]: ContractType.Trade,
	[ContractTypeDto.Other]: ContractType.Other,
};

const TO_DTO_MAP = reverseRecord(FROM_DTO_MAP);

/** Contract type mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractTypeMapper implements IMapper<ContractTypeDto, ContractType> {

	/** @inheritdoc */
	public fromDto(data: ContractTypeDto): ContractType {
		return FROM_DTO_MAP[data];
	}

	/** @inheritdoc */
	public toDto(data: ContractType): ContractTypeDto {
		return TO_DTO_MAP[data];
	}
}
