import { inject, InjectionToken, Provider } from '@angular/core';
import { PREVIEW_URL_PATH } from '@ygm/common/core/utils/constants';
import { buildRoutePaths } from '@ygm/common/core/utils/route-paths/build-route-paths';
import { commonRoutePaths } from '@ygm/common/core/utils/route-paths/common-route-paths';

/** Injection token that provide object with route web app paths. */
const WEB_ROUTE_PATHS_TOKEN = new InjectionToken<WebRoutePaths>('Provide object with web route paths');

export const EVENT_ID_PARAM_NAME = 'eventId';
export const PRODUCT_ID_PARAM_NAME = 'productId';
export const CONTRACT_ID_PARAM_NAME = 'contractId';
export const CATEGORY_ID_PARAM_NAME = 'categoryId';
export const LEVEL_ID_PARAM_NAME = 'levelId';

/**
 * Web route paths object.
 * It's intended to be used only in Routing modules.
 * So don't import this object directly into components.
 * Prefer to use `injectWebAppRoutes` instead.
 * It's necessary to make our component more flexible for unit tests.
 * @example
 * ```ts
 * const routes: Routes = [
 *   { path: webRoutePaths.home, component: HomePageComponent },
 *   // ...
 * ];
 * ```
 */
export const webRoutePaths = buildRoutePaths({
	...commonRoutePaths,
	landing: { path: 'landing', title: 'Landing' },
	preview: { path: PREVIEW_URL_PATH, title: 'Preview' },
	auth: {
		path: 'auth',
		children: {
			login: { path: 'login', title: 'Login' },
			forgotPassword: { path: 'forgot-password' },
			confirmPassword: { path: 'confirm-password' },
			register: { path: 'register' },
		},
	},
	businessInformation: { path: 'business-information' },
	remainingSponsorships: { path: 'remaining-sponsorships', title: 'Remaining Sponsorships' },
	resources: { path: 'resources', title: 'Resources' },
	renewalContracts: { path: 'renewal-contracts', title: 'Renewal Contracts' },
	event: {
		path: 'event',
		children: {
			overview: {
				path: `:${EVENT_ID_PARAM_NAME}`,
				children: {
					details: { path: 'details' },
					product: { path: `:${PRODUCT_ID_PARAM_NAME}` },
					members: { path: 'members' },
				},
			},
		},
	},
	dashboard: {
		path: 'dashboard',
		title: 'Dashboard',
	},
	leaderboard: { path: 'leaderboard', title: 'Leaderboard' },
	contracts: {
		path: 'contracts',
		title: 'Contracts',
		children: {
			create: { path: 'create', title: 'Create Contract' },
			edit: { path: `:${CONTRACT_ID_PARAM_NAME}/edit`, title: 'Edit Contract' },
			details: {
				path: `:${CONTRACT_ID_PARAM_NAME}`,
				children: {
					renewal: { path: 'renewal' },
				},
			},
		},
	},
	publicInvoice: {
		path: 'invoice',
		title: 'Invoice',
	},
	profile: {
		path: 'profile',
		title: 'Profile',
		children: { edit: { path: 'edit', title: 'Edit User' } },
	},
	contractSign: {
		path: `contract-sign`,
	},
	purchasingMembers: { path: `purchasing-members`, title: 'Purchasing Members' },
	marketingProducts: {
		path: `marketing-products`,
		title: 'Marketing Products',
		children: {
			details: {
				path: `:${CATEGORY_ID_PARAM_NAME}`,
			},
			levelDetails: {
				path: `levels/:${LEVEL_ID_PARAM_NAME}`,
			},
		},
	},
	notFound: { path: 'not-found' },
} as const);

type WebRoutePaths = typeof webRoutePaths;

/** Create provider for a web route paths. */
export function provideWebAppRoutes(): Provider {
	return {
		provide: WEB_ROUTE_PATHS_TOKEN,
		useValue: webRoutePaths,
	};
}

/**
 * Inject web app route paths to component.
 * Warning: Method should be called in the constructor phase to avoid runtime error because of `inject()`.
 * @example
 * ```ts
 * class SomeComponent {
 *   // ...
 *   protected readonly routePaths = injectWebAppRoutes();
 *   public constructor() { };
 * }
 * ```
 */
export function injectWebAppRoutes(): WebRoutePaths {
	return inject(WEB_ROUTE_PATHS_TOKEN);
}
