import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ForgotPasswordData } from '../models/login';

import { PasswordConfirmation } from '../models/password-confirmation';

import { LoginDataMapper } from './mappers/login-data.mapper';
import { AppConfig } from './app.config';
import { PasswordConfirmationMapper } from './mappers/password-confirmation.mapper';
import { AppErrorMapper } from './mappers/app-error.mapper';

export const RESET_PASSWORD_URL = '/api/v1/auth/password-reset/';
export const CONFIRM_PASSWORD_URL = '/api/v1/auth/password-reset-confirm/';

/** Chamber's subdomain. */
interface Subdomain {

	/** Subdomain. */
	readonly subdomain: string;
}

/** Credential service. */
@Injectable({
	providedIn: 'root',
})
export class CredentialsService {
	private readonly httpClient = inject(HttpClient);

	private readonly loginDataMapper = inject(LoginDataMapper);

	private readonly passwordConfirmationMapper = inject(PasswordConfirmationMapper);

	private readonly errorMapper = inject(AppErrorMapper);

	private readonly appConfigService = inject(AppConfig);

	/**
	 * Reset password.
	 * @param data Forgot password data.
	 */
	public reset(data: ForgotPasswordData): Observable<void> {
		return this.httpClient.post(
			new URL(RESET_PASSWORD_URL, this.appConfigService.apiUrl).toString(),
			this.loginDataMapper.forgotPasswordToDto(data),
		)
			.pipe(
				this.errorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.loginDataMapper),
				map(() => undefined),
			);
	}

	/**
	 * Confirm password.
	 * @param data Password confirmation data.
	 */
	public confirmPassword(data: PasswordConfirmation): Observable<Subdomain> {
		return this.httpClient.post<Subdomain>(
			new URL(CONFIRM_PASSWORD_URL, this.appConfigService.apiUrl).toString(),
			this.passwordConfirmationMapper.toDto(data),
		)
			.pipe(
				this.errorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.passwordConfirmationMapper),
			);
	}
}
