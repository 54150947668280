import { Injectable } from '@angular/core';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { CampaignUserRoleDto } from '../../../../../../admin-web/src/app/core/services/dto/campaign/campaign-user-role.dto';
import { CampaignUserRole } from '../../../../../../admin-web/src/app/core/models/campaign/campaign-user-role';

import { IMapperFromDto } from './mappers';

export const CAMPAIGN_USER_ROLE_FROM_DTO_MAP: Readonly<Record<CampaignUserRoleDto, CampaignUserRole>> = {
	[CampaignUserRoleDto.Chair]: CampaignUserRole.Chair,
	[CampaignUserRoleDto.ViceChair]: CampaignUserRole.ViceChair,
	[CampaignUserRoleDto.TeamCaptain]: CampaignUserRole.TeamCaptain,
	[CampaignUserRoleDto.Volunteer]: CampaignUserRole.Volunteer,
};

export const CAMPAIGN_USER_ROLE_TO_DTO_MAP = reverseRecord(CAMPAIGN_USER_ROLE_FROM_DTO_MAP);

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignUserRoleMapper implements IMapperFromDto<CampaignUserRoleDto, CampaignUserRole> {

	/** @inheritdoc */
	public fromDto(role: CampaignUserRoleDto): CampaignUserRole {
		return CAMPAIGN_USER_ROLE_FROM_DTO_MAP[role];
	}

	/** @inheritdoc */
	public toDto(role: CampaignUserRole): CampaignUserRoleDto {
		return CAMPAIGN_USER_ROLE_TO_DTO_MAP[role];
	}
}
