import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';

import { AppError, AppValidationError } from '../../models/app-error';
import { catchHttpErrorResponse } from '../../utils/rxjs/catch-http-error-response';

import { ValidationErrorDto } from '../dto/validation-error.dto';

import { IValidationErrorMapper } from './mappers';

/**
 * Could be a simple function that transform errors from DTO to domain-level errors
 * or an implementation of `IMapper` with implemented `validationErrorFromDto` method.
 */
export type ErrorMapper<TDto, TEntity extends Record<string, unknown>> =
  | IValidationErrorMapper<TDto, TEntity>
  | IValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/**
 * Errors mapper.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {
	/**
	 * Maps `HttpErrorResponse` to an application-level error.
	 * @param httpError Http error response.
	 */
	private fromDto(httpError: HttpErrorResponse): AppError {
		const { statusText, error } = httpError;
		return new AppError(error?.detail ?? statusText);
	}

	/**
	 * Is error a ValidationErrorDto.
	 * @param error Some error.
	 */
	private isValidationErrorDto<T>(error: HttpErrorResponse['error']): error is ValidationErrorDto<T> {
		return 'type' in error;
	}

	/**
	 * Maps `HttpErrorResponse` to either `AppError` or `AppValidationError`.
	 * @param httpError Http error.
	 * @param mapper Mapper for backend-provided validation data into domain validation data.
	 */
	private fromDtoWithValidationSupport<TDto, TEntity extends Record<string, unknown>>(
		httpError: HttpErrorResponse,
		mapper: ErrorMapper<TDto, TEntity>,
	): AppError | AppValidationError<TEntity> {
		if (httpError.status !== HttpStatusCode.BadRequest) {
			return this.fromDto(httpError);
		}

		const { error, statusText } = httpError;
		const convertedValue = error instanceof ArrayBuffer ? JSON.parse(new TextDecoder().decode(error)) : error;
		if (!this.isValidationErrorDto<TDto>(convertedValue)) {
			return this.fromDto(httpError);
		}

		const validationData =
      typeof mapper === 'function' ? mapper(convertedValue) : mapper.validationErrorFromDto(convertedValue);
		return new AppValidationError<TEntity>(statusText, validationData);
	}

	/**
	 * RxJS operator that catches `HttpErrorResponse` and maps it into application error.
	 */
	public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
		return catchHttpErrorResponse(error => {
			const appError = this.fromDto(error);
			return throwError(() => appError);
		});
	}

	/**
	 * RxJS operator that catches `HttpErrorResponse` and maps it into application error that may contain validation data.
	 * @param mapper Mapper for backend-provided validation data into domain validation data.
	 */
	public catchHttpErrorToAppErrorWithValidationSupport<
		T,
		TDto,

		// Using any so the method can correctly work with nested objects interfaces.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		TEntity extends Record<string, any>,
	>(mapper: ErrorMapper<TDto, TEntity>): MonoTypeOperatorFunction<T> {
		return catchHttpErrorResponse(error => {
			const appError = this.fromDtoWithValidationSupport<TDto, TEntity>(
				error,
				mapper,
			);
			return throwError(() => appError);
		});
	}
}
