import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../utils/window-token';

import { StorageService } from './storage.service';

/** Local storage service.*/
@Injectable({
	providedIn: 'root',
})
export class LocalStorageService extends StorageService {

	public constructor(@Inject(WINDOW) window: Window) {
		super(window.localStorage);
	}
}
