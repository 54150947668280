import { Injectable } from '@angular/core';
import { AppConfig } from '@ygm/common/core/services/app.config';
import { environment } from 'projects/public-web/src/environments/environment';

/** App-specific implementation of app config. */
@Injectable()
export class PublicAppConfig extends AppConfig {
	/** @inheritdoc */
	public readonly apiUrl: string = environment.apiUrl;

	/** @inheritdoc */
	public readonly version: string = environment.version;
}
