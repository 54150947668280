import { Injectable } from '@angular/core';
import { IMapperFromDto } from '@ygm/common/core/services/mappers/mappers';

import { USER_ROLE_FROM_DTO_MAP } from '@ygm/common/core/services/mappers/campaign-user-standing.mapper';

import { Team, TeamMember } from '../../models/team';
import { TeamDto, TeamMemberDto } from '../dto/team.dto';

/** Team mapper. */
@Injectable({
	providedIn: 'root',
})
export class TeamMapper implements IMapperFromDto<TeamDto, Team> {

	/** @inheritdoc */
	public fromDto(dto: TeamDto): Team {
		return {
			id: dto.id,
			name: dto.name,
			goal: dto.goal,
			members: dto.members.map(memberDto => this.fromTeamMemberDto(memberDto)),
		};
	}

	private fromTeamMemberDto(dto: TeamMemberDto): TeamMember {
		return {
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			campaignId: dto.campaign,
			email: dto.email,
			userId: dto.user,
			teamId: dto.team,
			companyName: dto.company_name,
			role: USER_ROLE_FROM_DTO_MAP[dto.role],
			avatar: dto.avatar,
		};
	}
}
