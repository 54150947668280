import { enumToArray } from '../utils/enum-to-array';

import { IndicatorColor } from './indicator-color';

/** Contract status. */
export enum ContractStatus {
	Draft = 'draft',
	PendingApproval = 'pendingApproval',
	Approved = 'approved',
	Sent = 'sent',
	Declined = 'declined',
	Signed = 'signed',
}

/** Contract status utility functions. */
export namespace ContractStatus {
	const TO_READABLE: Record<ContractStatus, string> = {
		[ContractStatus.Draft]: 'Draft',
		[ContractStatus.PendingApproval]: 'Pending Approval',
		[ContractStatus.Approved]: 'Approved',
		[ContractStatus.Sent]: 'Sent',
		[ContractStatus.Declined]: 'Declined',
		[ContractStatus.Signed]: 'Signed',
	};

	const TO_INDICATOR_COLOR_MAP: Record<ContractStatus, IndicatorColor> = {
		[ContractStatus.Draft]: IndicatorColor.Gray,
		[ContractStatus.PendingApproval]: IndicatorColor.LightBlue,
		[ContractStatus.Approved]: IndicatorColor.Green,
		[ContractStatus.Sent]: IndicatorColor.Blue,
		[ContractStatus.Declined]: IndicatorColor.Red,
		[ContractStatus.Signed]: IndicatorColor.Green,
	};

	/** Represent ContractStatus enum as an array. */
	export function toArray(): ContractStatus[] {
		return enumToArray(ContractStatus);
	}

	/**
	 * Provide human-readable value for contract status.
	 * @param status Contract status.
	 */
	export function toReadable(status: ContractStatus): string {
		return TO_READABLE[status];
	}

	/**
	 * Gets indicator color of contract status.
	 * @param status Contract status.
	 */
	export function toIndicatorColor(status: ContractStatus): IndicatorColor {
		return TO_INDICATOR_COLOR_MAP[status];
	}
}
