import { StrictOmit } from '@ygm/common/core/utils/types/strict-omit';

import { MeridiemTimeString } from '../utils/types/meridiem-time';

import { Chamber } from './chamber';
import { Weekday } from './weekday';
import { Option } from './option';
import { ReportTimezone } from './report-timezone';

/** Campaign. */
export interface Campaign {

	/** Id. */
	readonly id: number;

	/** Name. */
	readonly name: string;

	/** Status. */
	readonly status: CampaignStatus;

	/** Goal. */
	readonly goal: number | null;

	/** Year. */
	readonly year: number;

	/** Chamber id. */
	readonly chamberId?: Chamber['id'];

	/** Campaign's end date string (format: YYYY-mm-dd). */
	readonly endDate: string | null;

	/** Campaign's end date string (format: YYYY-mm-dd). */
	readonly startDate: string | null;

	/** Report close time. */
	readonly reportCloseTime: MeridiemTimeString | null;

	/** Report close weekday. */
	readonly reportCloseWeekday: Weekday | null;

	/** Whether campaign allows trades or not. */
	readonly hasTrades: boolean;

	/** Whether campaign allows vice chairs or not. */
	readonly hasViceChairs: boolean;

	/** Pre trc income. */
	readonly preTrcIncome: number;

	/** Timeline. */
	readonly timeline: Option['value'] | null;

	/** Whether the campaign is renewed or not. */
	readonly isRenewed: boolean;

	/** Report timezone. */
	readonly timezone: ReportTimezone | null;

	/** Whether campaign allows SMS contracts. */
	readonly allowSmsContracts: boolean;

	/** Campaign settings. */
	readonly settings: {

		/** Should the campaign goal be displayed. */
		readonly shouldShowCampaignGoal: boolean;

		/** Should the total earnings to date be displayed. */
		readonly shouldShowTotalEarningsToDate: boolean;

		/** Whether the campaign uses other type for incentives or not. */
		readonly campaignUsesOtherIncentives: boolean;
	};
}

export type CampaignEditData = StrictOmit<Campaign, 'id' | 'chamberId' | 'isRenewed' | 'startDate' | 'allowSmsContracts'>;
export type CampaignCreationData = Pick<Campaign, 'name' | 'year' | 'allowSmsContracts'> & {

	readonly chamberId: Chamber['id'];
};

/** Campaign status. */
export enum CampaignStatus {
	Created = 'created',
	Renewal = 'renewal',
	Live = 'live',
	Done = 'done',
	Closed = 'closed',
}
