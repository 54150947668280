import { Injectable, inject } from '@angular/core';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperFromDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';

import { Chamber } from '../../models/chamber';
import { ChamberCreationData } from '../../models/chamber/chamber-creation-data';
import { ChamberCreationDto } from '../dto/chamber-creation.dto';
import { ChamberDto } from '../dto/chamber.dto';

import { ChamberBrandingMapper } from './chamber-branding.mapper';

/** Chamber mapper. */
@Injectable({
	providedIn: 'root',
})
export class ChamberMapper implements IMapperFromDto<ChamberDto, Chamber>, IValidationErrorMapper<ChamberCreationDto, ChamberCreationData> {

	private readonly chamberBrandingMapper = inject(ChamberBrandingMapper);

	/** @inheritdoc */
	public fromDto(data: ChamberDto): Chamber {
		return {
			id: data.id,
			nickname: data.nickname,
			trcCoordFirstName: data.trc_coord_first_name,
			trcCoordLastName: data.trc_coord_last_name,
			email: data.trc_coord_email,
			phone: data.phone,
			sales: data.sales,
		};
	}

	/**
	 * Maps chamber creation data to dto.
	 * @param data Chamber creation data.
	 */
	public toCreationDto(data: ChamberCreationData): ChamberCreationDto {
		return {
			name: data.information.name,
			nickname: data.information.nickname,
			address: data.information.physicalAddress,
			city: data.information.physicalAddressCity,
			state: data.information.physicalAddressState,
			zipcode: data.information.physicalAddressZip,
			country: data.information.physicalAddressCountry,
			mail_address: data.information.mailingAddress,
			mail_state: data.information.mailingAddressState,
			mail_city: data.information.mailingAddressCity,
			mail_zipcode: data.information.mailingAddressZip,
			mail_country: data.information.mailingAddressCountry,
			phone: data.information.phoneNumber,
			subdomain: data.information.subdomain,
			note: '',
			member_count: data.information.numberOfMembers,
			city_population: data.information.cityPopulation,
			country_population: data.information.currentPopulation,
			msa_population: data.information.msaPopulation,
			total_budget: data.information.totalBudgetSize.toString(),
			total_membership_budget: data.information.totalMembershipBudgetSize.toString(),
			pre_income: data.information.sponsorshipIncome.toString(),
			trc_coord_first_name: data.trcCoordinatorInformation.firstName,
			trc_coord_last_name: data.trcCoordinatorInformation.lastName,
			trc_coord_title: data.trcCoordinatorInformation.positionTitle,
			trc_coord_phone: data.trcCoordinatorInformation.cellPhone,
			trc_coord_office_phone: data.trcCoordinatorInformation.officePhone,
			trc_coord_office_phone_ext: data.trcCoordinatorInformation.ext,
			trc_coord_email: data.trcCoordinatorInformation.email,
			ceo_first_name: data.ceoInformation.firstName,
			ceo_last_name: data.ceoInformation.lastName,
			ceo_email: data.ceoInformation.email,
			ceo_phone: data.ceoInformation.cellPhone,
			branding: this.chamberBrandingMapper.toDto({
				trcLogo: null,
				chamberLogo: data.logo,
				colors: data.colors,
				landingBackground: data.publicHomeBackground,
				publicDisplay: data.publicDisplay,
			}),
		};
	}

	/** @inheritdoc */
	public fromCreationDto(dto: ChamberCreationDto): ChamberCreationData {
		return {
			information: {
				id: dto.id ?? null,
				name: dto.name,
				nickname: dto.nickname,
				physicalAddress: dto.address,
				physicalAddressCity: dto.city,
				physicalAddressState: dto.state,
				physicalAddressZip: dto.zipcode,
				physicalAddressCountry: dto.country,
				mailingAddress: dto.mail_address,
				mailingAddressState: dto.mail_state,
				mailingAddressCity: dto.mail_city,
				mailingAddressZip: dto.mail_zipcode,
				mailingAddressCountry: dto.mail_country,
				phoneNumber: dto.phone,
				subdomain: dto.subdomain,
				numberOfMembers: dto.member_count,
				cityPopulation: dto.city_population,
				currentPopulation: dto.country_population,
				msaPopulation: dto.msa_population,
				totalBudgetSize: Number(dto.total_budget),
				totalMembershipBudgetSize: Number(dto.total_membership_budget),
				sponsorshipIncome: Number(dto.pre_income),
			},
			trcCoordinatorInformation: {
				firstName: dto.trc_coord_first_name,
				lastName: dto.trc_coord_last_name,
				positionTitle: dto.trc_coord_title,
				cellPhone: dto.trc_coord_phone,
				officePhone: dto.trc_coord_office_phone,
				ext: dto.trc_coord_office_phone_ext,
				email: dto.trc_coord_email,
			},
			ceoInformation: {
				firstName: dto.ceo_first_name,
				lastName: dto.ceo_last_name,
				email: dto.ceo_email,
				cellPhone: dto.ceo_phone,
			},
			colors: {
				primary: dto.branding.site_primary_color,
				secondary: dto.branding.site_secondary_color,
				alternate: dto.branding.site_alternate_color,
			},
			publicDisplay: {
				productDollars: dto.branding.headline,
				publicMessage: dto.branding.public_prelaunch_msg,
				volunteerMessage: dto.branding.volunteer_prelaunch_msg,
			},
			logo: dto.branding.chamber_logo,
			publicHomeBackground: dto.branding.landing_bg,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<ChamberCreationDto>): EntityValidationErrors<ChamberCreationData> {
		return {
			information: {
				name: extractErrorMessageByField('name', errorDto),
				nickname: extractErrorMessageByField('nickname', errorDto),
				physicalAddress: extractErrorMessageByField('address', errorDto),
				physicalAddressCity: extractErrorMessageByField('city', errorDto),
				physicalAddressState: extractErrorMessageByField('state', errorDto),
				physicalAddressZip: extractErrorMessageByField('zipcode', errorDto),
				physicalAddressCountry: extractErrorMessageByField('country', errorDto),
				mailingAddress: extractErrorMessageByField('mail_address', errorDto),
				mailingAddressState: extractErrorMessageByField('mail_state', errorDto),
				mailingAddressCity: extractErrorMessageByField('mail_city', errorDto),
				mailingAddressZip: extractErrorMessageByField('mail_zipcode', errorDto),
				mailingAddressCountry: extractErrorMessageByField('mail_country', errorDto),
				phoneNumber: extractErrorMessageByField('phone', errorDto),
				subdomain: extractErrorMessageByField('subdomain', errorDto),
				numberOfMembers: extractErrorMessageByField('member_count', errorDto),
				cityPopulation: extractErrorMessageByField('city_population', errorDto),
				currentPopulation: extractErrorMessageByField('country_population', errorDto),
				msaPopulation: extractErrorMessageByField('msa_population', errorDto),
				totalBudgetSize: extractErrorMessageByField('total_budget', errorDto),
				totalMembershipBudgetSize: extractErrorMessageByField('total_membership_budget', errorDto),
				sponsorshipIncome: extractErrorMessageByField('pre_income', errorDto),
			},
			trcCoordinatorInformation: {
				firstName: extractErrorMessageByField('trc_coord_first_name', errorDto),
				lastName: extractErrorMessageByField('trc_coord_last_name', errorDto),
				cellPhone: extractErrorMessageByField('trc_coord_phone', errorDto),
				email: extractErrorMessageByField('trc_coord_email', errorDto),
				positionTitle: extractErrorMessageByField('trc_coord_title', errorDto),
				officePhone: extractErrorMessageByField('trc_coord_office_phone', errorDto),
				ext: extractErrorMessageByField('trc_coord_office_phone_ext', errorDto),
			},
			ceoInformation: {
				firstName: extractErrorMessageByField('ceo_first_name', errorDto),
				lastName: extractErrorMessageByField('ceo_last_name', errorDto),
				email: extractErrorMessageByField('ceo_email', errorDto),
				cellPhone: extractErrorMessageByField('ceo_phone', errorDto),
			},
			colors: {
				primary: extractErrorMessageByField('branding.site_primary_color', errorDto),
				secondary: extractErrorMessageByField('branding.site_secondary_color', errorDto),
				alternate: extractErrorMessageByField('branding.site_alternate_color', errorDto),
			},
			publicDisplay: {
				productDollars: extractErrorMessageByField('branding.headline', errorDto),
				publicMessage: extractErrorMessageByField('branding.public_prelaunch_msg', errorDto),
				volunteerMessage: extractErrorMessageByField('branding.volunteer_prelaunch_msg', errorDto),
			},
			logo: extractErrorMessageByField('branding.chamber_logo', errorDto),
			publicHomeBackground: extractErrorMessageByField('branding.landing_bg', errorDto),
		};
	}
}
