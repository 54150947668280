import { Injectable, inject } from '@angular/core';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { CampaignEditData } from '../../models/campaign';
import { ReportTimezone } from '../../models/report-timezone';
import { Weekday } from '../../models/weekday';
import { CampaignEditDto, ReportTimezoneDto, WeekdayDto } from '../dto/campaign.dto';

import { CampaignReportTimeMapper } from './campaign-report-time.mapper';
import { CAMPAIGN_STATUS_TO_DTO_MAP } from './campaign.mapper';

export const WEEKDAY_MAP_FROM_DTO: Record<WeekdayDto, Weekday> = {
	[WeekdayDto.Monday]: Weekday.Monday,
	[WeekdayDto.Tuesday]: Weekday.Tuesday,
	[WeekdayDto.Wednesday]: Weekday.Wednesday,
	[WeekdayDto.Thursday]: Weekday.Thursday,
	[WeekdayDto.Friday]: Weekday.Friday,
	[WeekdayDto.Saturday]: Weekday.Saturday,
	[WeekdayDto.Sunday]: Weekday.Sunday,
};
const WEEKDAY_MAP_TO_DTO = reverseRecord(WEEKDAY_MAP_FROM_DTO);

export const REPORT_TIMEZONE_MAP_FROM_DTO: Record<ReportTimezoneDto, ReportTimezone> = {
	[ReportTimezoneDto.USCentral]: ReportTimezone.USCentral,
	[ReportTimezoneDto.USPacific]: ReportTimezone.USPacific,
	[ReportTimezoneDto.USMountain]: ReportTimezone.USMountain,
	[ReportTimezoneDto.USEastern]: ReportTimezone.USEastern,
};
const REPORT_TIMEZONE_MAP_TO_DTO = reverseRecord(REPORT_TIMEZONE_MAP_FROM_DTO);

/** Campaign edit data mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignEditDataMapper implements
IMapperToDto<CampaignEditDto, CampaignEditData>,
IValidationErrorMapper<CampaignEditDto, CampaignEditData> {

	private readonly campaignTimeMapper = inject(CampaignReportTimeMapper);

	/** @inheritdoc */
	public toDto(data: CampaignEditData): CampaignEditDto {
		return {
			name: data.name,
			has_trades: data.hasTrades,
			has_vice_chairs: data.hasViceChairs,
			timeline: data.timeline,
			status: CAMPAIGN_STATUS_TO_DTO_MAP[data.status],
			end_date: data.endDate,
			report_close_time: data.reportCloseTime ? this.campaignTimeMapper.toDto(data.reportCloseTime) : null,
			report_close_weekday: data.reportCloseWeekday ? WEEKDAY_MAP_TO_DTO[data.reportCloseWeekday] : null,
			goal: data.goal?.toString() ?? null,
			year: data.year,
			pre_trc_income: data.preTrcIncome,
			timezone: data.timezone ? REPORT_TIMEZONE_MAP_TO_DTO[data.timezone] : null,
			settings: {
				show_campaign_goal: data.settings.shouldShowCampaignGoal,
				show_total_earnings_to_date: data.settings.shouldShowTotalEarningsToDate,
				campaign_uses_other_incentives: data.settings.campaignUsesOtherIncentives,
			},
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<CampaignEditDto>): EntityValidationErrors<CampaignEditData> {
		return {
			name: extractErrorMessageByField('name', errorDto),
			hasTrades: extractErrorMessageByField('has_trades', errorDto),
			hasViceChairs: extractErrorMessageByField('has_vice_chairs', errorDto),
			endDate: extractErrorMessageByField('end_date', errorDto),
			timeline: extractErrorMessageByField('timeline', errorDto),
			reportCloseTime: extractErrorMessageByField('report_close_time', errorDto),
			reportCloseWeekday: extractErrorMessageByField('report_close_weekday', errorDto),
			goal: extractErrorMessageByField('goal', errorDto),
			status: extractErrorMessageByField('status', errorDto),
		};
	}
}
