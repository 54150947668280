import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchHttpErrorResponse } from '@ygm/common/core/utils/rxjs/catch-http-error-response';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from '@ygm/common/core/services/notification.service';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';

const ERROR_MESSAGE = 'Something went wrong! Please try again later or contact with support.';
const PUBLIC_URL = '/sign';
const PUBLIC_ERROR_MESSAGE = 'Please reach out to your chamber volunteer for further assistance.';

/** Catches errors from server and triggers error notifier with a message. */
@Injectable()
export class ErrorCatchInterceptor implements HttpInterceptor {
	private readonly notificationService = inject(NotificationService);

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				const errorDto: ValidationErrorDto<unknown> =
					error.error instanceof ArrayBuffer ? JSON.parse(new TextDecoder().decode(error.error)) : error.error;

				if (error.status === 401) {
					return throwError(() => error);
				}

				// Show message for errors with status code is 403.
				if (errorDto.type === 'client_error' && error.status === 403) {
					if (errorDto.errors.length > 0) {
						const errorMessage = this.isPublicUrl(req.url) ?
							[errorDto.errors[0].detail, PUBLIC_ERROR_MESSAGE] :
							errorDto.errors[0].detail;
						this.notificationService.setError(errorMessage);
					} else {
						this.notificationService.setError(ERROR_MESSAGE);
					}
					return throwError(() => error);
				}

				// Show user friendly error message if the error type is not `validation_error` and 403 error.
				if (errorDto.type !== 'validation_error') {
					this.notificationService.setError(ERROR_MESSAGE);
					return throwError(() => error);
				}

				// Show user friendly error message for the array of errors with `null` attribute field.
				if (errorDto.errors.some(err => err.attr === null)) {
					this.notificationService.setError(
						errorDto.errors.filter(err => err.attr === null).map(err => err.detail),
					);
					return throwError(() => error);
				}

				// Show `non_field_errors` message to user.
				const nonFieldError = errorDto.errors.find(e => e.attr === 'non_field_errors');
				if (nonFieldError) {
					this.notificationService.setError(nonFieldError.detail);
				}

				return throwError(() => error);
			}),
		);
	}

	private isPublicUrl(requestUrl: string): boolean {
		return requestUrl.includes(PUBLIC_URL);
	}
}
