import { Injectable } from '@angular/core';
import { ContractStatus } from '@ygm/common/core/models/contract-status';
import { ContractStatusDto } from '@ygm/common/core/services/dto/contract-status.dto';
import { IMapper } from '@ygm/common/core/services/mappers/mappers';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

const FROM_DTO_MAP: Readonly<Record<ContractStatusDto, ContractStatus>> = {
	[ContractStatusDto.Draft]: ContractStatus.Draft,
	[ContractStatusDto.Sent]: ContractStatus.Sent,
	[ContractStatusDto.Approved]: ContractStatus.Approved,
	[ContractStatusDto.PendingApproval]: ContractStatus.PendingApproval,
	[ContractStatusDto.Declined]: ContractStatus.Declined,
	[ContractStatusDto.Signed]: ContractStatus.Signed,
};

const TO_DTO_MAP = reverseRecord(FROM_DTO_MAP);

/** Contract status mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractStatusMapper implements IMapper<ContractStatusDto, ContractStatus> {

	/** @implements */
	public fromDto(data: ContractStatusDto): ContractStatus {
		return FROM_DTO_MAP[data];
	}

	/** @implements */
	public toDto(data: ContractStatus): ContractStatusDto {
		return TO_DTO_MAP[data];
	}
}
