import { Injectable } from '@angular/core';

import { SortOptions, SortDirection } from '../../models/sort-options';

/** Mapper for sort options. */
@Injectable({ providedIn: 'root' })
export class SortMapper {

	/**
	 * Mapper of sort to dto.
	 * @param data Sort options.
	 * @param fieldMap Mapping of fields to the ones that are backend-acceptable.
	 */
	public toDto<T extends number | string, U extends unknown>(
		data: SortOptions<T>,
		fieldMap: Record<T, U>,
	): string {
		if (data.direction == null || data.field == null) {
			return '';
		}
		const prefix = data.direction === SortDirection.Ascending ? '' : '-';
		return `${prefix}${fieldMap[data.field]}`;
	}
}
