import { IMapper, IMapperToEditDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';
import { AppError, EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { Injectable } from '@angular/core';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';

import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { ChamberBranding, ChamberBrandingEditData, PublicDisplayEditData } from '@ygm/common/core/models/chamber/chamber-branding';
import { ChamberBrandingDto, ChamberBrandingEditDto, PublicDisplayEditDto } from '@ygm/common/core/services/dto/chamber-creation.dto';

/** Chamber branding mapper. */
@Injectable({
	providedIn: 'root',
})
export class ChamberBrandingMapper implements
IMapper<ChamberBrandingDto, ChamberBranding>,
IMapperToEditDto<ChamberBrandingEditDto, ChamberBrandingEditData>,
IValidationErrorMapper<ChamberBrandingEditDto, ChamberBrandingEditData> {
	/** @inheritdoc */
	public fromDto(data: ChamberBrandingDto): ChamberBranding {
		return {
			colors: {
				primary: data.site_primary_color,
				secondary: data.site_secondary_color,
				alternate: data.site_alternate_color,
			},
			chamberLogo: data.chamber_logo,
			trcLogo: data.trc_logo,
			landingBackground: data.landing_bg,
			publicDisplay: {
				publicMessage: data.public_prelaunch_msg,
				volunteerMessage: data.volunteer_prelaunch_msg,
				productDollars: data.headline,
			},
		};
	}

	/** @inheritdoc */
	public toDto(data: ChamberBranding): ChamberBrandingDto {
		if (data.trcLogo instanceof File || data.landingBackground instanceof File || data.chamberLogo instanceof File) {
			throw new AppError('Branding files objects should be uploaded to s3 and passed as URL');
		}
		return {
			site_alternate_color: data.colors.alternate,
			site_secondary_color: data.colors.secondary,
			site_primary_color: data.colors.primary,
			headline: data.publicDisplay.productDollars,
			public_prelaunch_msg: data.publicDisplay.publicMessage,
			volunteer_prelaunch_msg: data.publicDisplay.volunteerMessage,
			trc_logo: data.trcLogo,
			chamber_logo: data.chamberLogo,
			landing_bg: data.landingBackground,
		};
	}

	/** @inheritdoc */
	public toEditDto(data: ChamberBrandingEditData): ChamberBrandingEditDto {
		if (data.trcLogo instanceof File || data.landingBackground instanceof File || data.chamberLogo instanceof File) {
			throw new AppError('Branding files objects should be uploaded to s3 and passed as URL');
		}

		return {
			site_alternate_color: data.colors.alternate,
			site_secondary_color: data.colors.secondary,
			site_primary_color: data.colors.primary,
			headline: data.productDollars,
			trc_logo: data.trcLogo,
			chamber_logo: data.chamberLogo,
			landing_bg: data.landingBackground,
		};
	}

	/**
	 * Maps public display edit data to Dto.
	 * @param data Public display edit data.
	 */
	public toPublicDisplayEditDto(data: PublicDisplayEditData): PublicDisplayEditDto {
		return {
			public_prelaunch_msg: data.publicMessage,
			volunteer_prelaunch_msg: data.volunteerMessage,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<ChamberBrandingEditDto>): EntityValidationErrors<ChamberBrandingEditData> {
		return {
			productDollars: extractErrorMessageByField('headline', errorDto),
			trcLogo: extractErrorMessageByField('trc_logo', errorDto),
			chamberLogo: extractErrorMessageByField('chamber_logo', errorDto),
			landingBackground: extractErrorMessageByField('landing_bg', errorDto),
			colors: {
				alternate: extractErrorMessageByField('site_alternate_color', errorDto),
				secondary: extractErrorMessageByField('site_secondary_color', errorDto),
				primary: extractErrorMessageByField('site_primary_color', errorDto),
			},
		};
	}
}
