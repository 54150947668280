import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { composeHttpParams } from '@ygm/common/core/utils/compose-http-params';
import { getWindowUrlSubdomain } from '@ygm/common/core/utils/parse-window-url';
import { BehaviorSubject, Observable, catchError, combineLatest, distinctUntilChanged, map, of, shareReplay, switchMap, tap } from 'rxjs';

import { APP_BASE_HREF } from '@angular/common';

import { ChamberProfileDetails } from '../models/chamber/chamber-profile-details';
import { assertNonNullWithReturn } from '../utils/assert-non-null';

import { AppConfig } from './app.config';
import { ChamberExistenceInfoDto } from './dto/chamber-existence-info-dto';
import { ChamberProfileDetailsMapper } from './mappers/chamber-profile-details.mapper';

export const SUBDOMAIN_CHECK_PATH = '/api/v1/chamber-subdomain-existence/';
export const ADMIN_BASE_HREF = '/admin/';

/** Service for site's subdomain validation. */
@Injectable({
	providedIn: 'root',
})
export class ChamberValidationService {

	/** Whether the subdomain is valid or not. */
	public readonly isSubdomainValid$: Observable<boolean>;

	/** Chamber profile details. */
	public readonly chamberInformation$: Observable<ChamberProfileDetails>;

	private readonly baseHref = inject(APP_BASE_HREF);

	private readonly urlSubdomain = getWindowUrlSubdomain();

	private readonly subdomain$ = new BehaviorSubject(this.urlSubdomain);

	private readonly httpClient = inject(HttpClient);

	private readonly appConfigService = inject(AppConfig);

	private _chamberInformation: ChamberProfileDetails | null = null;

	private readonly chamberProfileDetailsMapper = inject(ChamberProfileDetailsMapper);

	private readonly _refreshChamberInformation$ = new BehaviorSubject<void>(undefined);

	public constructor() {
		this.chamberInformation$ = combineLatest([
			this.subdomain$.pipe(distinctUntilChanged()),
			this._refreshChamberInformation$,
		]).pipe(
			switchMap(([subdomain]) => this.getChamberBySubdomain(subdomain)),
			tap(info => {
				this._chamberInformation = info;
			}),
			shareReplay({ refCount: false, bufferSize: 1 }),
		);

		this.isSubdomainValid$ = this.chamberInformation$.pipe(
			map(() => true),

			// Set 'false' if chamber with provided subdomain doesn't exist and request returns 404 error.
			catchError(() => of(false)),
		);
	}

	/** Gets chamber information. */
	public get chamberInformation(): ChamberProfileDetails {
		return assertNonNullWithReturn(this._chamberInformation);
	}

	/**
	 * Validates site's subdomain.
	 * @param subdomain Subdomain to validate.
	 */
	private getChamberBySubdomain(subdomain: string): Observable<ChamberProfileDetails> {
		const params = composeHttpParams({ subdomain, admin: this.baseHref === ADMIN_BASE_HREF });
		const url = new URL(SUBDOMAIN_CHECK_PATH, this.appConfigService.apiUrl).toString();

		return this.httpClient.get<ChamberExistenceInfoDto>(url, { params }).pipe(
			map(response => this.chamberProfileDetailsMapper.fromDto({ ...response.chamber, campaign: response.campaign })),
		);
	}

	/** Refresh chamber information. */
	public refreshChamberInformation(): void {
		this._refreshChamberInformation$.next();
	}

	/**
	 * Set a specific subdomain to validate, default subdomain is the subdomain from the URL.
	 * @param subdomain New subdomain.
	 */
	public setSubdomain(subdomain: string | undefined): void {
		if (!subdomain) {
			this.subdomain$.next(this.urlSubdomain);
			return;
		}
		this.subdomain$.next(subdomain);
	}
}
